import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import routeConfiguration from '../../routeConfiguration';
import { pathByRouteName } from '../../util/routes';
import { apiBaseUrl } from '../../util/api';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { isSignupEmailTakenError } from '../../util/errors';
import {
  Page,
  NamedLink,
  NamedRedirect,
  SocialLoginButton,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  Modal,
  TermsOfService,
  UnsavedInfoPrompt,
} from '../../components';
import { ConfirmSignupForm, LoginForm, SignupForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import {
  login,
  authenticationInProgress,
  signup,
  signupWithIdp,
  createSignUpProjectListing,
} from '../../ducks/Auth.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';

import css from './AuthenticationPage.css';
import { FacebookLogo, GoogleLogo } from './socialLoginLogos';

export class AuthenticationPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tosModalOpen: false,
      authError: Cookies.get('st-autherror')
        ? JSON.parse(Cookies.get('st-autherror').replace('j:', ''))
        : null,
      authInfo: Cookies.get('st-authinfo')
        ? JSON.parse(Cookies.get('st-authinfo').replace('j:', ''))
        : null,
    };
  }

  componentDidMount() {
    // Remove the autherror cookie once the content is saved to state
    // because we don't want to show the error message e.g. after page refresh
    Cookies.remove('st-autherror');
  }

  render() {
    const {
      authInProgress,
      currentUser,
      intl,
      isAuthenticated,
      location,
      loginError,
      scrollingDisabled,
      signupError,
      submitLogin,
      submitSignup,
      confirmError,
      submitSingupWithIdp,
      tab,
      onManageDisableScrolling,
      showUnsavedPrompt,
    } = this.props;

    const isConfirm = tab === 'confirm';
    const isLogin = tab === 'login';
    const isSignup = tab === 'signup';
    const locationFrom = location.state && location.state.from ? location.state.from : null;
    const authinfoFrom =
      this.state.authInfo && this.state.authInfo.from ? this.state.authInfo.from : null;
    const from = locationFrom ? locationFrom : authinfoFrom ? authinfoFrom : null;

    const user = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!user.id;

    const providerFormData =
      typeof window !== 'undefined' && !!window.sessionStorage
        ? JSON.parse(sessionStorage.getItem('providerFormData'))
        : null;
    const isSignupProvider = providerFormData !== null;

    const signUpFromProjectCategoryLandingPage =
      typeof window !== 'undefined' && !!window.sessionStorage
        ? JSON.parse(sessionStorage.getItem('signUpFromProjectCategoryLandingPage'))
        : null;

    const signUpProjectFormData =
      typeof window !== 'undefined' &&
      !!window.sessionStorage &&
      signUpFromProjectCategoryLandingPage
        ? JSON.parse(sessionStorage.getItem('signUpProjectFormData'))
        : null;

    // Already authenticated, redirect away from auth page
    if (isAuthenticated && from) {
      return <Redirect to={from} />;
    } else if (isAuthenticated && currentUserLoaded) {
      return <NamedRedirect name="DashboardPage" />;
    }

    const loginErrorMessage = (
      <div className={css.error}>
        <FormattedMessage id="AuthenticationPage.loginFailed" />
      </div>
    );

    const signupErrorMessage = (
      <div className={css.error}>
        {isSignupEmailTakenError(signupError) ? (
          <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
        ) : (
          <FormattedMessage id="AuthenticationPage.signupFailed" />
        )}
      </div>
    );

    const confirmErrorMessage = confirmError ? (
      <div className={css.error}>
        {isSignupEmailTakenError(confirmError) ? (
          <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
        ) : (
          <FormattedMessage id="AuthenticationPage.signupFailed" />
        )}
      </div>
    ) : null;

    // eslint-disable-next-line no-confusing-arrow
    const errorMessage = (error, message) => (error ? message : null);
    const loginOrSignupError = isLogin
      ? errorMessage(loginError, loginErrorMessage)
      : errorMessage(signupError, signupErrorMessage);

    const headerLogin = (
      <h1 className={css.tab}>
        <FormattedMessage id="AuthenticationPage.loginLinkText" />
      </h1>
    );

    const headerSignup = (
      <h1 className={css.tab}>
        <FormattedMessage id="AuthenticationPage.signupLinkText" />
      </h1>
    );

    const handleSubmitSignup = values => {
      const {
        fname,
        lname,
        accountRole,
        industry,
        lookingFor,
        projectDuration,
        phoneNumber,
        dateOfBirth,
        birthLocation,
        companyName,
        address,
        city,
        postalCode,
        taxIdentificationNumber,
        tinCountry,
        businessRegistrationNumber,
        businessRegistrationCountryOfIssue,
        ...rest
      } = values;
      const {
        languages,
        location,
        experience,
        education,
        workingMethod,
        workedWith,
        workExperience,
        whoAmI,
        hourlyFee,
        bio,
        dontShowHourlyFee,
        dontAddEducation,
        dontAddWorkedWith,
        dontAddWorkExperience,
        website,
        linkedIn,
        cocAccepted,
        fnameProvider,
        lnameProvider,
      } = providerFormData || {};
      const signUpFrom = signUpFromProjectCategoryLandingPage || null;

      const isCompanyAbroad = accountRole === 'company-abroad';

      let account_role;
      if (accountRole === 'company-abroad') {
        account_role = 'light-entrepreneur';
      } else if (!isSignupProvider) {
        account_role = 'customer';
      } else {
        account_role = accountRole;
      }

      const firstName = isSignupProvider ? fnameProvider.trim() : fname.trim();
      const lastName = isSignupProvider ? lnameProvider.trim() : lname.trim();

      const params = {
        firstName: firstName,
        lastName: lastName,
        account_role,
        signUpFrom: signUpFrom,
        dateOfBirth,
        birthLocation,
        companyName,
        address,
        city,
        postalCode,
        taxIdentificationNumber,
        tinCountry,
        businessRegistrationNumber,
        businessRegistrationCountryOfIssue,
        isCompanyAbroad,
        ...rest,
        languages: languages?.map(lang => ({
          language: lang?.language?.key,
          level: lang?.level?.key,
        })),
        experience: experience?.map(exp => ({
          category: exp?.category?.key,
          level: exp?.level?.key,
          isMainSkill: exp?.isMainSkill,
        })),
        education: dontAddEducation
          ? []
          : education?.map(edu => ({
              ...edu,
              when: edu?.when?.key,
            })),
        workedWith: dontAddWorkedWith
          ? []
          : workedWith?.map(ww => ({
              ...ww,
              endMonth: ww?.endMonth?.key,
              endYear: ww?.endYear?.key,
              startMonth: ww?.startMonth?.key,
              startYear: ww?.startYear?.key,
            })),
        workExperience: dontAddWorkExperience
          ? []
          : workExperience?.map(we => ({
              ...we,
              endMonth: we?.endMonth?.key,
              endYear: we?.endYear?.key,
              startMonth: we?.startMonth?.key,
              startYear: we?.startYear?.key,
            })),
        location,
        workingMethod,
        whoAmI,
        hourlyFee,
        bio,
        dontShowHourlyFee,
        website,
        linkedIn,
        cocAccepted,
        industry,
        lookingFor: lookingFor?.key,
        projectDuration: projectDuration?.key,
        phoneNumber: '+' + phoneNumber,
        textMessageNotifications: true,
      };

      submitSignup(params, signUpProjectFormData);
    };

    const handleSubmitConfirm = values => {
      const { idpToken, email, firstName, lastName, idpId } = this.state.authInfo;
      const {
        email: newEmail,
        firstName: newFirstName,
        lastName: newLastName,
        vat_number,
        accountRole,
        phoneNumber,
        industry,
        lookingFor,
        projectDuration,
      } = values;
      const {
        languages,
        location,
        experience,
        education,
        workingMethod,
        workedWith,
        workExperience,
        whoAmI,
        hourlyFee,
        bio,
        dontShowHourlyFee,
        dontAddEducation,
        dontAddWorkedWith,
        dontAddWorkExperience,
        website,
        linkedIn,
        cocAccepted,
      } = providerFormData || {};

      let account_role;
      if (accountRole === 'company-abroad') {
        account_role = 'light-entrepreneur';
      } else if (!isSignupProvider) {
        account_role = 'customer';
      } else {
        account_role = accountRole;
      }

      // Pass email, fistName or lastName to Flex API only if user has edited them
      // sand they can't be fetched directly from idp provider (e.g. Facebook)

      const authParams = {
        ...(newEmail !== email && { email: newEmail }),
        ...(newFirstName !== firstName && { firstName: newFirstName }),
        ...(newLastName !== lastName && { lastName: newLastName }),
        vat_number,
        account_role,
        phoneNumber: '+' + phoneNumber,
        textMessageNotifications: true,
        industry,
        lookingFor: lookingFor?.key,
        projectDuration: projectDuration?.key,
        languages: languages?.map(lang => ({
          language: lang?.language?.key,
          level: lang?.level?.key,
        })),
        experience: experience?.map(exp => ({
          category: exp?.category?.key,
          level: exp?.level?.key,
          isMainSkill: exp?.isMainSkill,
        })),
        education: dontAddEducation
          ? []
          : education?.map(edu => ({
              ...edu,
              when: edu?.when?.key,
            })),
        workedWith: dontAddWorkedWith
          ? []
          : workedWith?.map(ww => ({
              ...ww,
              endMonth: ww?.endMonth?.key,
              endYear: ww?.endYear?.key,
              startMonth: ww?.startMonth?.key,
              startYear: ww?.startYear?.key,
            })),
        workExperience: dontAddWorkExperience
          ? []
          : workExperience?.map(we => ({
              ...we,
              endMonth: we?.endMonth?.key,
              endYear: we?.endYear?.key,
              startMonth: we?.startMonth?.key,
              startYear: we?.startYear?.key,
            })),
        location,
        workingMethod,
        whoAmI,
        hourlyFee,
        bio,
        dontShowHourlyFee,
        website,
        linkedIn,
        cocAccepted,
      };

      submitSingupWithIdp(
        {
          idpToken,
          idpId,
          ...authParams,
        },
        signUpProjectFormData
      );
    };

    const getDefaultRoutes = () => {
      const routes = routeConfiguration();
      const baseUrl = apiBaseUrl();

      // Route where the user should be returned after authentication
      // This is used e.g. with EditListingPage and ListingPage
      const fromParam = from ? `from=${from}` : '';

      // Default route where user is returned after successfull authentication
      const defaultReturn = pathByRouteName('DashboardPage', routes);
      const defaultReturnParam = defaultReturn ? `&defaultReturn=${defaultReturn}` : '';

      // Route for confirming user data before creating a new user
      const defaultConfirm = pathByRouteName('ConfirmPage', routes);
      const defaultConfirmParam = defaultConfirm ? `&defaultConfirm=${defaultConfirm}` : '';

      return { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam };
    };

    const authWithFacebook = () => {
      const defaultRoutes = getDefaultRoutes();
      const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;

      window.location.href = `${baseUrl}/api/auth/facebook?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
    };

    const authWithGoogle = () => {
      const defaultRoutes = getDefaultRoutes();
      const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;

      window.location.href = `${baseUrl}/api/auth/google/?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
    };

    const idp = this.state.authInfo
      ? this.state.authInfo.idpId.replace(/^./, str => str.toUpperCase())
      : null;

    // Form for confirming information from IdP (e.g. Facebook)
    // before new user is created to Flex
    const confirmForm = (
      <div className={css.content}>
        <h1 className={css.signupWithIdpTitle}>
          <FormattedMessage id="AuthenticationPage.confirmSignupWithIdpTitle" values={{ idp }} />
        </h1>

        <p className={css.confirmInfoText}>
          <FormattedMessage id="AuthenticationPage.confirmSignupInfoText" />
        </p>
        {confirmErrorMessage}
        <ConfirmSignupForm
          className={css.form}
          onSubmit={handleSubmitConfirm}
          inProgress={authInProgress}
          onOpenTermsOfService={() => this.setState({ tosModalOpen: true })}
          authInfo={this.state.authInfo}
          isSignupProvider={isSignupProvider}
        />
      </div>
    );

    // Social login buttons
    const showFacebookLogin = !!process.env.REACT_APP_FACEBOOK_APP_ID;
    const showGoogleLogin = !!process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const showSocialLogins = showFacebookLogin || showGoogleLogin;

    const facebookButtonText = isLogin ? (
      <FormattedMessage id="AuthenticationPage.loginWithFacebook" />
    ) : (
      <FormattedMessage id="AuthenticationPage.signupWithFacebook" />
    );

    const googleButtonText = isLogin ? (
      <FormattedMessage id="AuthenticationPage.loginWithGoogle" />
    ) : (
      <FormattedMessage id="AuthenticationPage.signupWithGoogle" />
    );
    const socialLoginButtonsMaybe = showSocialLogins ? (
      <div className={css.idpButtons}>
        <div className={css.socialButtonsOr}>
          <span className={css.socialButtonsOrText}>
            <FormattedMessage id="AuthenticationPage.or" />
          </span>
        </div>

        {showFacebookLogin ? (
          <div className={css.socialButtonWrapper}>
            <SocialLoginButton onClick={() => authWithFacebook()}>
              <span className={css.buttonIcon}>{FacebookLogo}</span>
              {facebookButtonText}
            </SocialLoginButton>
          </div>
        ) : null}

        {showGoogleLogin ? (
          <div className={css.socialButtonWrapper}>
            <SocialLoginButton onClick={() => authWithGoogle()}>
              <span className={css.buttonIcon}>{GoogleLogo}</span>
              {googleButtonText}
            </SocialLoginButton>
          </div>
        ) : null}
      </div>
    ) : null;

    const authenticationForms = isLogin ? (
      <div className={css.content}>
        {headerLogin}
        {loginOrSignupError}
        <LoginForm className={css.form} onSubmit={submitLogin} inProgress={authInProgress} />
        {socialLoginButtonsMaybe}
        <div>
          <div className={css.socialButtonsOr}>
            <span className={css.socialButtonsOrText}>
              <FormattedMessage id="AuthenticationPage.notSignedYet" />
            </span>
          </div>
          <NamedLink name="SignupPage" className={css.signupLink}>
            <FormattedMessage id="TopbarDesktop.signup" />
          </NamedLink>
        </div>
      </div>
    ) : (
      <div>
        {headerSignup}
        {loginOrSignupError}
        <SignupForm
          className={css.form}
          onSubmit={handleSubmitSignup}
          inProgress={authInProgress}
          onOpenTermsOfService={() => this.setState({ tosModalOpen: true })}
          isSignupProvider={isSignupProvider}
        />
      </div>
    );

    const formContent = isConfirm ? confirmForm : authenticationForms;

    const siteTitle = config.siteTitle;
    const schemaTitle = isLogin
      ? intl.formatMessage({ id: 'AuthenticationPage.schemaTitleLogin' }, { siteTitle })
      : intl.formatMessage({ id: 'AuthenticationPage.schemaTitleSignup' }, { siteTitle });

    // const topbarClasses = classNames({
    //   [css.hideOnMobile]: showEmailVerification,
    // });

    if (isSignup) {
      return (
        <div>
          {formContent}
          <Modal
            id="AuthenticationPage.tos"
            isOpen={this.state.tosModalOpen}
            onClose={() => this.setState({ tosModalOpen: false })}
            usePortal
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.termsWrapper}>
              <h2 className={css.termsHeading}>
                <FormattedMessage id="AuthenticationPage.termsHeading" />
              </h2>
              <TermsOfService />
            </div>
          </Modal>
          <UnsavedInfoPrompt when={showUnsavedPrompt && !isAuthenticated} />
        </div>
      );
    } else {
      return (
        <Page
          title={schemaTitle}
          scrollingDisabled={scrollingDisabled}
          schema={{
            '@context': 'http://schema.org',
            '@type': 'WebPage',
            name: schemaTitle,
          }}
        >
          <LayoutSingleColumn>
            <LayoutWrapperTopbar>
              <TopbarContainer currentPage="AuthenticationPage" authenticationPageTopBar={true} />
            </LayoutWrapperTopbar>
            <LayoutWrapperMain className={css.layoutWrapperMain}>
              <div className={css.root}>{formContent}</div>
              <Modal
                id="AuthenticationPage.tos"
                isOpen={this.state.tosModalOpen}
                onClose={() => this.setState({ tosModalOpen: false })}
                usePortal
                onManageDisableScrolling={onManageDisableScrolling}
              >
                <div className={css.termsWrapper}>
                  <h2 className={css.termsHeading}>
                    <FormattedMessage id="AuthenticationPage.termsHeading" />
                  </h2>
                  <TermsOfService />
                </div>
              </Modal>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }
  }
}

AuthenticationPageComponent.defaultProps = {
  currentUser: null,
  loginError: null,
  signupError: null,
  confirmError: null,
  tab: 'signup',
  sendVerificationEmailError: null,
  showSocialLoginsForTests: false,
  showUnsavedPrompt: false,
};

const { bool, func, object, oneOf, shape } = PropTypes;

AuthenticationPageComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
  loginError: propTypes.error,
  scrollingDisabled: bool.isRequired,
  signupError: propTypes.error,
  confirmError: propTypes.error,

  submitLogin: func.isRequired,
  submitSignup: func.isRequired,
  onCreateSignUpProjectListing: func.isRequired,
  tab: oneOf(['login', 'signup', 'confirm']),

  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  showUnsavedPrompt: bool,

  // from withRouter
  location: shape({ state: object }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated, loginError, signupError, confirmError } = state.Auth;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;

  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    scrollingDisabled: isScrollingDisabled(state),
    signupError,
    confirmError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  };
};

const mapDispatchToProps = dispatch => ({
  submitLogin: ({ email, password }) => dispatch(login(email, password)),
  submitSignup: (params, signUpProjectFormData) => dispatch(signup(params, signUpProjectFormData)),
  submitSingupWithIdp: (params, signUpProjectFormData) =>
    dispatch(signupWithIdp(params, signUpProjectFormData)),
  onCreateSignUpProjectListing: params => dispatch(createSignUpProjectListing(params)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AuthenticationPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(AuthenticationPageComponent);

export default AuthenticationPage;
