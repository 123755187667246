import React, { useEffect, useState } from 'react';
import { string, func } from 'prop-types';
import truncate from 'lodash/truncate';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import { findOptionsForSelectFilter, buildCategoryHash } from '../../util/search';
import config from '../../config';
import { NamedLink, VerifiedUserMark } from '../../components';

import css from './ProjectListingCard.css';
import renderHTML from 'react-render-html';

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

export const ProjectListingCardComponent = props => {
  const { className, rootClassName, intl, listing, setActiveListing } = props;

  const [finalDate, setFinalDate] = useState('');

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const {
    title = '',
    description = '',
    price,
    publicData = {},
    createdAt,
  } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const category = publicData.category;
  const priceEstimate = publicData?.priceEstimate;

  const { formattedPrice, priceTitle } = priceData(price, intl);

  const isProject = publicData?.projectDuration === 'project';

  const unitTranslationKey = isProject
    ? 'ProjectListingCard.perProject'
    : 'ProjectListingCard.perMonth';

  const finalDateLabel = (
    <FormattedMessage id="ListingCard.finalApplicationDateLabel"></FormattedMessage>
  );

  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
  const categoryHash = buildCategoryHash(categoryOptions);
  const categoryName = category ? categoryHash[category]?.label : null; // category ? categoryHash[category].label : null;

  const truncatedDescription = truncate(description, {
    length: 400,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });

  const fireGtmEvent = () => {
    if (typeof window === 'object') {
      window.dataLayer.push({
        event: 'select_item',
        ecommerce: {
          items: [
            {
              item_name: listing.attributes.title,
              item_id: listing.id.uuid,
              price: '' + listing.attributes.price.amount / 100,
              item_brand: listing.author.attributes.profile.displayName,
              item_category: listing.attributes.publicData.category,
              item_list_name: 'search_results',
              quantity: '1',
            },
          ],
        },
      });
    }
  };

  useEffect(() => {
    function calculateFinalDate() {
      const tempTime = new Date(createdAt);
      const date = new Date(tempTime.setDate(tempTime.getDate() + 60));
      if (config.locale === 'fi') {
        setFinalDate(date.toLocaleDateString('fi-FI'));
      } else {
        setFinalDate(
          date.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })
        );
      }
    }
    calculateFinalDate();
  }, [createdAt]);

  return (
    <NamedLink className={classes} name="ProjectListingPage" params={{ id, slug }}>
      <div onClick={fireGtmEvent} className={css.container}>
        <div
          className={css.threeToTwoWrapper}
          onMouseEnter={() => setActiveListing(currentListing.id)}
          onMouseLeave={() => setActiveListing(null)}
        >
          <div className={css.aspectWrapper}>
            <div className={css.title}>{title}</div>
            <div className={css.description}>
              {publicData?.descriptionText
                ? renderHTML(publicData?.descriptionText)
                : truncatedDescription}
            </div>
          </div>
        </div>
        <div className={css.info}>
          <div className={css.title}>{categoryName}</div>
        </div>
        <div className={css.mainInfo}>
          <div className={css.price}>
            <div className={css.perUnit}>
              <FormattedMessage id={unitTranslationKey} />
            </div>
            <div className={css.priceValue} title={priceTitle}>
              {priceEstimate ? priceEstimate : formattedPrice}
            </div>
          </div>
          <div className={css.authorInfo}>
            {authorName} <VerifiedUserMark user={author} />
          </div>
        </div>
        <div className={css.finalDate}>{/* {finalDateLabel}
          {finalDate} */}</div>
      </div>
    </NamedLink>
  );
};

ProjectListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ProjectListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ProjectListingCardComponent);
