import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { types as sdkTypes } from '../../util/sdkLoader';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureListing, ensureTransaction, ensureOwnListing } from '../../util/data';
import { dateFromAPIToLocalNoon } from '../../util/dates';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  createSlug,
} from '../../util/urlHelpers';
import { txIsPaymentPending } from '../../util/transaction';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  NamedRedirect,
  TransactionPanel,
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
} from '../../components';
import { TopbarContainer } from '../../containers';

import {
  acceptSale,
  declineSale,
  releaseFunds,
  loadData,
  setInitialValues,
  sendMessage,
  sendReview,
  fetchMoreMessages,
  fetchTransactionLineItems,
  sendCustomOffer,
  declineProjectBid,
  sendProjectBid,
  showProfileListing,
  sendEnquiryCustomer,
  sendEnquiryProvider,
  jobReady,
  jobReadyCustomer,
  jobDoneReleaseFunds,
} from './TransactionPage.duck';
import css from './TransactionPage.css';

const { Money, UUID } = sdkTypes;

const PROVIDER = 'provider';
const CUSTOMER = 'customer';

// TransactionPage handles data loading for Sale and Order views to transaction pages in Inbox.
export const TransactionPageComponent = props => {
  const {
    currentUser,
    initialMessageFailedToTransaction,
    savePaymentMethodFailed,
    fetchMessagesError,
    fetchMessagesInProgress,
    totalMessagePages,
    oldestMessagePageFetched,
    fetchTransactionError,
    history,
    intl,
    messages,
    onManageDisableScrolling,
    onSendMessage,
    onSendReview,
    onShowMoreMessages,
    params,
    scrollingDisabled,
    sendMessageError,
    sendMessageInProgress,
    sendReviewError,
    sendReviewInProgress,
    transaction,
    transactionRole,
    acceptInProgress,
    acceptSaleError,
    declineInProgress,
    declineSaleError,
    onAcceptSale,
    onDeclineSale,
    onJobReady,
    onJobReadyCustomer,
    timeSlots,
    fetchTimeSlotsError,
    processTransitions,
    callSetInitialValues,
    onInitializeCardPaymentData,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    onReleaseFunds,
    releaseFundsInProgress,
    releaseFundsError,
    onSendCustomOffer,
    sendCustomOfferInProgress,
    sendCustomOfferError,
    onDeclineProjectBid,
    declineProjectBidInProgress,
    declineProjectBidError,
    onSendProjectBid,
    sendProjectBidInProgress,
    sendProjectBidError,
    onShowProfileListing,
    showProfileListingInProgress,
    showProfileListingError,
    projectTransaction,
    sendEnquiryErrorListing,
    sendEnquiryErrorProject,
    onJobDoneReleaseFunds,
    jobDoneReleaseFundsInProgress,
    jobDoneReleaseFundsError,
  } = props;

  const currentTransaction = ensureTransaction(transaction);
  const currentListing = ensureListing(currentTransaction.listing);
  const isProviderRole = transactionRole === PROVIDER;
  const isCustomerRole = transactionRole === CUSTOMER;

  const redirectToCheckoutPageWithInitialValues = (initialValues, listing) => {
    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
    callSetInitialValues(setInitialValues, initialValues);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: currentListing.id.uuid, slug: createSlug(currentListing.attributes.title) },
        {}
      )
    );
  };

  const onSubmitEnquiryCustomer = values => {
    const { history, params, onSendEnquiryCustomer, getListing, getOwnListing } = props;
    const routes = routeConfiguration();
    const currentListingID = ensureListing(currentTransaction.listing);
    const listingId = new UUID(currentListingID.id.uuid);
    const { message } = values;
    const isPendingApprovalVariant = params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = params.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));
    const author = currentListing && currentListing.author;
    const isLightEntrepreneur =
      author &&
      author.attributes &&
      author.attributes.profile.publicData &&
      author.attributes.profile.publicData.account_role === 'light-entrepreneur';

    onSendEnquiryCustomer(listingId, isLightEntrepreneur, message.trim())
      .then(txId => {
        // this.setState({ enquiryModalOpen: false });

        if (typeof window === 'object') {
          window.dataLayer.push({
            event: 'contact_author',
            ecommerce: {
              items: [
                {
                  item_name: currentListing.attributes.title,
                  item_id: currentListing.id.uuid,
                  price: '' + currentListing.attributes.price.amount / 100,
                  item_brand: currentListing.author.attributes.profile.displayName,
                  item_category: currentListing.attributes.publicData.category,
                  item_list_name: 'listing_page',
                },
              ],
            },
          });
        }

        // Redirect to OrderDetailsPage

        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  };

  const handlePackageSubmit = values => {
    const { history, getListing, callSetInitialValues, onInitializeCardPaymentData } = props;
    const currentListingID = ensureListing(currentTransaction.listing);
    const listingId = new UUID(currentListingID.id.uuid);
    const listing = getListing(listingId);

    const { ...bookingData } = values;
    let startDate = new Date();
    startDate.setDate(startDate.getDate() + 1);
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 2);

    const initialValues = {
      listing,
      bookingData: bookingData,
      bookingDates: {
        bookingStart: startDate,
        bookingEnd: endDate,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  };

  const onSubmitEnquiryProvider = values => {
    const { history, params, onSendEnquiryProvider, getListing, getOwnListing } = props;
    const routes = routeConfiguration();
    const currentListingID = ensureListing(currentTransaction.listing);
    const listingId = new UUID(currentListingID.id.uuid);
    const { message } = values;
    const isPendingApprovalVariant = params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = params.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    onSendEnquiryProvider(listingId, message.trim())
      .then(txId => {
        // this.setState({ enquiryModalOpen: false });

        if (typeof window === 'object') {
          window.dataLayer.push({
            event: 'contact_author',
            ecommerce: {
              items: [
                {
                  item_name: currentListing.attributes.title,
                  item_id: currentListing.id.uuid,
                  price: '' + currentListing.attributes.price.amount / 100,
                  item_brand: currentListing.author.attributes.profile.displayName,
                  item_category: currentListing.attributes.publicData.category,
                  item_list_name: 'listing_page',
                },
              ],
            },
          });
        }

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  };

  const onSubmitNewProjectEnquiry = values => {
    const { history, params, onSendEnquiryProvider, getListing, getOwnListing } = props;
    const routes = routeConfiguration();
    const currentListingID = ensureListing(projectTransaction.listing);
    const listingId = new UUID(currentListingID.id.uuid);
    const { message } = values;
    const isPendingApprovalVariant = params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = params.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    onSendEnquiryProvider(listingId, message.trim())
      .then(txId => {
        // this.setState({ enquiryModalOpen: false });

        if (typeof window === 'object') {
          window.dataLayer.push({
            event: 'contact_author',
            ecommerce: {
              items: [
                {
                  item_name: currentListing.attributes.title,
                  item_id: currentListing.id.uuid,
                  price: '' + currentListing.attributes.price.amount / 100,
                  item_brand: currentListing.author.attributes.profile.displayName,
                  item_category: currentListing.attributes.publicData.category,
                  item_list_name: 'listing_page',
                },
              ],
            },
          });
        }

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  };

  // If payment is pending, redirect to CheckoutPage
  if (
    txIsPaymentPending(currentTransaction) &&
    isCustomerRole &&
    currentTransaction.attributes.lineItems
  ) {
    const currentBooking = ensureListing(currentTransaction.booking);

    const initialValues = {
      listing: currentListing,
      // Transaction with payment pending should be passed to CheckoutPage
      transaction: currentTransaction,
      // Original bookingData content is not available,
      // but it is already used since booking is created.
      // (E.g. quantity is used when booking is created.)
      bookingData: {},
      bookingDates: {
        bookingStart: dateFromAPIToLocalNoon(currentBooking.attributes.start),
        bookingEnd: dateFromAPIToLocalNoon(currentBooking.attributes.end),
      },
    };

    redirectToCheckoutPageWithInitialValues(initialValues, currentListing);
  }

  // Customer can create a booking, if the tx is in "enquiry" state.
  const handleSubmitBookingRequest = values => {
    const { skipAddToCartEvent = false, ...bookingData } = values;

    const startTime = new Date();
    startTime.setUTCMinutes(Math.ceil((startTime.getUTCMinutes() + 5) / 5) * 5, 0, 0);
    const endTime = new Date();
    endTime.setUTCMinutes(Math.ceil((endTime.getUTCMinutes() + 10) / 5) * 5, 0, 0);

    const priceAmount =
      values.pricingPackage === 'package1'
        ? currentListing.attributes?.price?.amount
        : values.pricingPackage &&
          currentListing.attributes.publicData[`${values.pricingPackage}Price`]
        ? currentListing.attributes.publicData[`${values.pricingPackage}Price`]
        : 0;
    const pricingPackageName =
      values.pricingPackage && currentListing.attributes.publicData[`${values.pricingPackage}Name`]
        ? currentListing.attributes.publicData[`${values.pricingPackage}Name`]
        : '';

    if (!skipAddToCartEvent) {
      if (typeof window === 'object') {
        window.dataLayer.push({
          event: 'add_to_cart',
          ecommerce: {
            items: [
              {
                item_name: currentListing.attributes.title,
                item_id: currentListing.id.uuid,
                price: '' + priceAmount / 100,
                item_brand: currentTransaction.provider.attributes.profile.displayName,
                item_category: currentListing.attributes.publicData.category,
                item_list_name: 'chat_window',
                item_variant: pricingPackageName,
                custom_offer: 'false',
                custom_offer_value: null,
                quantity: '1',
              },
            ],
          },
        });
      }
    }

    const initialValues = {
      listing: currentListing,
      // enquired transaction should be passed to CheckoutPage
      transaction: currentTransaction,
      bookingData,
      bookingDates: {
        bookingStart: startTime,
        bookingEnd: endTime,
      },
      confirmPaymentError: null,
    };

    redirectToCheckoutPageWithInitialValues(initialValues, currentListing);
  };

  // Customer can create a booking, if the tx is in "enquiry" state.
  const handleSubmitAcceptBidRequest = values => {
    onShowProfileListing(currentTransaction.customer.id).then(profileListing => {
      const { ...bookingData } = values;
      const startTime = new Date();
      startTime.setUTCMinutes(Math.ceil((startTime.getUTCMinutes() + 5) / 5) * 5, 0, 0);
      const endTime = new Date();
      endTime.setUTCMinutes(Math.ceil((endTime.getUTCMinutes() + 10) / 5) * 5, 0, 0);

      profileListing.attributes.price = new Money(
        currentTransaction.attributes.protectedData.projectBidAmount,
        'EUR'
      );

      const initialValues = {
        listing: profileListing,
        bookingData,
        bookingDates: {
          bookingStart: startTime,
          bookingEnd: endTime,
        },
        confirmPaymentError: null,
        projectTransaction: currentTransaction,
      };

      const routes = routeConfiguration();
      // Customize checkout page state with current listing and selected bookingDates
      const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

      callSetInitialValues(setInitialValues, initialValues);

      // Clear previous Stripe errors from store if there is any
      onInitializeCardPaymentData();

      // Redirect to CheckoutPage
      history.push(
        createResourceLocatorString(
          'CheckoutPage',
          routes,
          { id: profileListing.id.uuid, slug: createSlug(profileListing.attributes.title) },
          {}
        )
      );
    });
  };

  const deletedListingTitle = intl.formatMessage({
    id: 'TransactionPage.deletedListing',
  });
  const listingTitle = currentListing.attributes.deleted
    ? deletedListingTitle
    : currentListing.attributes.title;

  // Redirect users with someone else's direct link to their own inbox/sales or inbox/orders page.
  const isDataAvailable =
    currentUser &&
    currentTransaction.id &&
    currentTransaction.id.uuid === params.id &&
    currentTransaction.attributes.lineItems &&
    currentTransaction.customer &&
    currentTransaction.provider &&
    !fetchTransactionError;

  const isOwnSale =
    isDataAvailable &&
    isProviderRole &&
    currentUser.id.uuid === currentTransaction.provider.id.uuid;
  const isOwnOrder =
    isDataAvailable &&
    isCustomerRole &&
    currentUser.id.uuid === currentTransaction.customer.id.uuid;

  if (isDataAvailable && isProviderRole && !isOwnSale) {
    // eslint-disable-next-line no-console
    console.error('Tried to access a sale that was not owned by the current user');
    return <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />;
  } else if (isDataAvailable && isCustomerRole && !isOwnOrder) {
    // eslint-disable-next-line no-console
    console.error('Tried to access an order that was not owned by the current user');
    return <NamedRedirect name="InboxPage" params={{ tab: 'orders' }} />;
  }

  const detailsClassName = classNames(css.tabContent, css.tabContentVisible);

  const fetchErrorMessage = isCustomerRole
    ? 'TransactionPage.fetchOrderFailed'
    : 'TransactionPage.fetchSaleFailed';
  const loadingMessage = isCustomerRole
    ? 'TransactionPage.loadingOrderData'
    : 'TransactionPage.loadingSaleData';

  const loadingOrFailedFetching = fetchTransactionError ? (
    <p className={css.error}>
      <FormattedMessage id={`${fetchErrorMessage}`} />
    </p>
  ) : (
    <p className={css.loading}>
      <FormattedMessage id={`${loadingMessage}`} />
    </p>
  );

  const initialMessageFailed = !!(
    initialMessageFailedToTransaction &&
    currentTransaction.id &&
    initialMessageFailedToTransaction.uuid === currentTransaction.id.uuid
  );

  // TransactionPanel is presentational component
  // that currently handles showing everything inside layout's main view area.
  const panel = isDataAvailable ? (
    <TransactionPanel
      className={detailsClassName}
      currentUser={currentUser}
      transaction={currentTransaction}
      fetchMessagesInProgress={fetchMessagesInProgress}
      totalMessagePages={totalMessagePages}
      oldestMessagePageFetched={oldestMessagePageFetched}
      messages={messages}
      initialMessageFailed={initialMessageFailed}
      savePaymentMethodFailed={savePaymentMethodFailed}
      fetchMessagesError={fetchMessagesError}
      sendMessageInProgress={sendMessageInProgress}
      sendMessageError={sendMessageError}
      sendReviewInProgress={sendReviewInProgress}
      sendReviewError={sendReviewError}
      onManageDisableScrolling={onManageDisableScrolling}
      onShowMoreMessages={onShowMoreMessages}
      onSendMessage={onSendMessage}
      onSendReview={onSendReview}
      transactionRole={transactionRole}
      onAcceptSale={onAcceptSale}
      onDeclineSale={onDeclineSale}
      onJobReady={onJobReady}
      onJobReadyCustomer={onJobReadyCustomer}
      acceptInProgress={acceptInProgress}
      declineInProgress={declineInProgress}
      acceptSaleError={acceptSaleError}
      declineSaleError={declineSaleError}
      nextTransitions={processTransitions}
      onSubmitBookingRequest={handleSubmitBookingRequest}
      timeSlots={timeSlots}
      fetchTimeSlotsError={fetchTimeSlotsError}
      onFetchTransactionLineItems={onFetchTransactionLineItems}
      lineItems={lineItems}
      fetchLineItemsInProgress={fetchLineItemsInProgress}
      fetchLineItemsError={fetchLineItemsError}
      onReleaseFunds={onReleaseFunds}
      releaseFundsInProgress={releaseFundsInProgress}
      releaseFundsError={releaseFundsError}
      onSendCustomOffer={onSendCustomOffer}
      sendCustomOfferInProgress={sendCustomOfferInProgress}
      sendCustomOfferError={sendCustomOfferError}
      onDeclineProjectBid={onDeclineProjectBid}
      declineProjectBidInProgress={declineProjectBidInProgress}
      declineProjectBidError={declineProjectBidError}
      callSetInitialValues={callSetInitialValues}
      setInitialValues={setInitialValues}
      onSendProjectBid={onSendProjectBid}
      sendProjectBidInProgress={sendProjectBidInProgress}
      sendProjectBidError={sendProjectBidError}
      onSubmitAcceptBidRequest={handleSubmitAcceptBidRequest}
      showProfileListingInProgress={showProfileListingInProgress}
      showProfileListingError={showProfileListingError}
      projectTransaction={projectTransaction}
      onSubmitEnquiryCustomer={onSubmitEnquiryCustomer}
      onSubmitEnquiryProvider={onSubmitEnquiryProvider}
      onSubmitNewProjectEnquiry={onSubmitNewProjectEnquiry}
      sendEnquiryErrorListing={sendEnquiryErrorListing}
      sendEnquiryErrorProject={sendEnquiryErrorProject}
      handlePackageSubmit={handlePackageSubmit}
      onJobDoneReleaseFunds={onJobDoneReleaseFunds}
      jobDoneReleaseFundsInProgress={jobDoneReleaseFundsInProgress}
      jobDoneReleaseFundsError={jobDoneReleaseFundsError}
    />
  ) : (
    loadingOrFailedFetching
  );

  return (
    <Page
      title={intl.formatMessage({ id: 'TransactionPage.title' }, { title: listingTitle })}
      scrollingDisabled={scrollingDisabled}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.root}>{panel}</div>
        </LayoutWrapperMain>
      </LayoutSingleColumn>
    </Page>
  );
};

TransactionPageComponent.defaultProps = {
  currentUser: null,
  fetchTransactionError: null,
  acceptSaleError: null,
  declineSaleError: null,
  transaction: null,
  fetchMessagesError: null,
  initialMessageFailedToTransaction: null,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsError: null,
  sendEnquiryErrorListing: null,
  sendEnquiryErrorProject: null,
};

const { bool, func, oneOf, shape, string, array, arrayOf, number } = PropTypes;

TransactionPageComponent.propTypes = {
  params: shape({ id: string }).isRequired,
  transactionRole: oneOf([PROVIDER, CUSTOMER]).isRequired,
  currentUser: propTypes.currentUser,
  fetchTransactionError: propTypes.error,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  onJobReady: func.isRequired,
  onJobReadyCustomer: func.isRequired,
  scrollingDisabled: bool.isRequired,
  transaction: propTypes.transaction,
  fetchMessagesError: propTypes.error,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailedToTransaction: propTypes.uuid,
  savePaymentMethodFailed: bool,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  callSetInitialValues: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  onFetchTransactionLineItems: func.isRequired,
  onSendEnquiryCustomer: func.isRequired,
  sendEnquiryErrorListing: propTypes.error,
  sendEnquiryErrorProject: propTypes.error,

  // line items
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    fetchTransactionError,
    acceptSaleError,
    declineSaleError,
    acceptInProgress,
    declineInProgress,
    transactionRef,
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    messages,
    initialMessageFailedToTransaction,
    savePaymentMethodFailed,
    sendMessageInProgress,
    sendMessageError,
    sendReviewInProgress,
    sendReviewError,
    timeSlots,
    fetchTimeSlotsError,
    processTransitions,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    releaseFundsInProgress,
    releaseFundsError,
    sendCustomOfferInProgress,
    sendCustomOfferError,
    declineProjectBidInProgress,
    declineProjectBidError,
    sendProjectBidInProgress,
    sendProjectBidError,
    showProfileListingInProgress,
    showProfileListingError,
    projectTransactionRef,
    sendEnquiryErrorListing,
    sendEnquiryErrorProject,
    jobDoneReleaseFundsInProgress,
    jobDoneReleaseFundsError,
  } = state.TransactionPage;
  const { currentUser } = state.user;

  const transactions = getMarketplaceEntities(state, transactionRef ? [transactionRef] : []);
  const transaction = transactions.length > 0 ? transactions[0] : null;
  const projectTransactions = getMarketplaceEntities(
    state,
    projectTransactionRef ? [projectTransactionRef] : []
  );
  const projectTransaction = projectTransactions.length > 0 ? projectTransactions[0] : null;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    currentUser,
    getListing,
    getOwnListing,
    fetchTransactionError,
    acceptSaleError,
    declineSaleError,
    acceptInProgress,
    declineInProgress,
    scrollingDisabled: isScrollingDisabled(state),
    transaction,
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    messages,
    initialMessageFailedToTransaction,
    savePaymentMethodFailed,
    sendMessageInProgress,
    sendMessageError,
    sendReviewInProgress,
    sendReviewError,
    timeSlots,
    fetchTimeSlotsError,
    processTransitions,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    releaseFundsInProgress,
    releaseFundsError,
    sendCustomOfferInProgress,
    sendCustomOfferError,
    declineProjectBidInProgress,
    declineProjectBidError,
    sendProjectBidInProgress,
    sendProjectBidError,
    showProfileListingInProgress,
    showProfileListingError,
    projectTransaction,
    sendEnquiryErrorListing,
    sendEnquiryErrorProject,
    jobDoneReleaseFundsInProgress,
    jobDoneReleaseFundsError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAcceptSale: transactionId => dispatch(acceptSale(transactionId)),
    onDeclineSale: transactionId => dispatch(declineSale(transactionId)),
    onJobReady: transactionId => dispatch(jobReady(transactionId)),
    onJobReadyCustomer: transactionId => dispatch(jobReadyCustomer(transactionId)),
    onReleaseFunds: transactionId => dispatch(releaseFunds(transactionId)),
    onShowMoreMessages: txId => dispatch(fetchMoreMessages(txId)),
    onSendMessage: (txId, message) => dispatch(sendMessage(txId, message)),
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    onSendReview: (role, tx, reviewRating, reviewContent) =>
      dispatch(sendReview(role, tx, reviewRating, reviewContent)),
    callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
    onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
    onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
      dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
    onSendCustomOffer: (id, description, customOfferAmount, intl, deliveryDate) =>
      dispatch(sendCustomOffer(id, description, customOfferAmount, intl, deliveryDate)),
    onDeclineProjectBid: transactionId => dispatch(declineProjectBid(transactionId)),
    onSendProjectBid: (listingId, transactionId, description, amount, intl, deliveryDate) =>
      dispatch(sendProjectBid(listingId, transactionId, description, amount, intl, deliveryDate)),
    onShowProfileListing: userId => dispatch(showProfileListing(userId)),
    onSendEnquiryCustomer: (listingId, isLightEntrepreneur, message) =>
      dispatch(sendEnquiryCustomer(listingId, isLightEntrepreneur, message)),
    onSendEnquiryProvider: (listingId, message) =>
      dispatch(sendEnquiryProvider(listingId, message)),
    onJobDoneReleaseFunds: transactionId => dispatch(jobDoneReleaseFunds(transactionId)),
  };
};

const TransactionPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(TransactionPageComponent);

TransactionPage.loadData = loadData;
TransactionPage.setInitialValues = setInitialValues;

export default TransactionPage;
