import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { stringifyDateToISO8601 } from '../../util/dates';
import { isTransactionsTransitionInvalidTransition, storableError } from '../../util/errors';
import {
  txIsEnquired,
  getReview1Transition,
  getReview2Transition,
  txIsInFirstReviewBy,
  TRANSITION_ACCEPT,
  TRANSITION_DECLINE,
  TRANSITION_RELEASE_FUNDS,
  TRANSITION_MAKE_CUSTOM_OFFER,
  TRANSITION_DECLINE_OFFER,
  TRANSITION_MAKE_PROJECT_BID_AFTER_ENQUIRY,
  TRANSITION_ENQUIRE,
  TRANSITION_JOB_DONE,
  TRANSITION_JOB_DONE_CUSTOMER,
  TRANSITION_JOB_DONE_RELEASE_FUNDS,
} from '../../util/transaction';
import {
  projectTransitionPrivileged,
  transactionLineItems,
  markTxUnread,
  markTxRead,
  completePayoutTransaction,
  cancelPayoutTransaction,
} from '../../util/api';
import * as log from '../../util/log';
import {
  updatedEntities,
  denormalisedEntities,
  denormalisedResponseEntities,
} from '../../util/data';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  fetchCurrentUserHasOrdersSuccess,
  fetchCurrentUserNotifications,
  fetchCurrentUser,
} from '../../ducks/user.duck';

const { UUID } = sdkTypes;

const MESSAGES_PAGE_SIZE = 100;
const CUSTOMER = 'customer';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/TransactionPage/SET_INITIAL_VALUES';

export const FETCH_TRANSACTION_REQUEST = 'app/TransactionPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/TransactionPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/TransactionPage/FETCH_TRANSACTION_ERROR';

export const FETCH_TRANSITIONS_REQUEST = 'app/TransactionPage/FETCH_TRANSITIONS_REQUEST';
export const FETCH_TRANSITIONS_SUCCESS = 'app/TransactionPage/FETCH_TRANSITIONS_SUCCESS';
export const FETCH_TRANSITIONS_ERROR = 'app/TransactionPage/FETCH_TRANSITIONS_ERROR';

export const ACCEPT_SALE_REQUEST = 'app/TransactionPage/ACCEPT_SALE_REQUEST';
export const ACCEPT_SALE_SUCCESS = 'app/TransactionPage/ACCEPT_SALE_SUCCESS';
export const ACCEPT_SALE_ERROR = 'app/TransactionPage/ACCEPT_SALE_ERROR';

export const DECLINE_SALE_REQUEST = 'app/TransactionPage/DECLINE_SALE_REQUEST';
export const DECLINE_SALE_SUCCESS = 'app/TransactionPage/DECLINE_SALE_SUCCESS';
export const DECLINE_SALE_ERROR = 'app/TransactionPage/DECLINE_SALE_ERROR';

export const FETCH_MESSAGES_REQUEST = 'app/TransactionPage/FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'app/TransactionPage/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'app/TransactionPage/FETCH_MESSAGES_ERROR';

export const SEND_MESSAGE_REQUEST = 'app/TransactionPage/SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'app/TransactionPage/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'app/TransactionPage/SEND_MESSAGE_ERROR';

export const SEND_REVIEW_REQUEST = 'app/TransactionPage/SEND_REVIEW_REQUEST';
export const SEND_REVIEW_SUCCESS = 'app/TransactionPage/SEND_REVIEW_SUCCESS';
export const SEND_REVIEW_ERROR = 'app/TransactionPage/SEND_REVIEW_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/TransactionPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/TransactionPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/TransactionPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/TransactionPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/TransactionPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/TransactionPage/FETCH_LINE_ITEMS_ERROR';

export const RELEASE_FUNDS_REQUEST = 'app/TransactionPage/RELEASE_FUNDS_REQUEST';
export const RELEASE_FUNDS_SUCCESS = 'app/TransactionPage/RELEASE_FUNDS_SUCCESS';
export const RELEASE_FUNDS_ERROR = 'app/TransactionPage/RELEASE_FUNDS_ERROR';

export const SEND_CUSTOM_OFFER_REQUEST = 'app/TransactionPage/SEND_CUSTOM_OFFER_REQUEST';
export const SEND_CUSTOM_OFFER_SUCCESS = 'app/TransactionPage/SEND_CUSTOM_OFFER_SUCCESS';
export const SEND_CUSTOM_OFFER_ERROR = 'app/TransactionPage/SEND_CUSTOM_OFFER_ERROR';

export const ACCEPT_CUSTOM_OFFER_REQUEST = 'app/TransactionPage/ACCEPT_CUSTOM_OFFER_REQUEST';
export const ACCEPT_CUSTOM_OFFER_SUCCESS = 'app/TransactionPage/ACCEPT_CUSTOM_OFFER_SUCCESS';
export const ACCEPT_CUSTOM_OFFER_ERROR = 'app/TransactionPage/ACCEPT_CUSTOM_OFFER_ERROR';

export const DECLINE_PROJECT_BID_REQUEST = 'app/TransactionPage/DECLINE_PROJECT_BID_REQUEST';
export const DECLINE_PROJECT_BID_SUCCESS = 'app/TransactionPage/DECLINE_PROJECT_BID_SUCCESS';
export const DECLINE_PROJECT_BID_ERROR = 'app/TransactionPage/DECLINE_PROJECT_BID_ERROR';

export const SEND_PROJECT_BID_REQUEST = 'app/TransactionPage/SEND_PROJECT_BID_REQUEST';
export const SEND_PROJECT_BID_SUCCESS = 'app/TransactionPage/SEND_PROJECT_BID_SUCCESS';
export const SEND_PROJECT_BID_ERROR = 'app/TransactionPage/SEND_PROJECT_BID_ERROR';

export const SHOW_PROFILE_LISTING_REQUEST = 'app/TransactionPage/SHOW_PROFILE_LISTING_REQUEST';
export const SHOW_PROFILE_LISTING_SUCCESS = 'app/TransactionPage/SHOW_PROFILE_LISTING_SUCCESS';
export const SHOW_PROFILE_LISTING_ERROR = 'app/TransactionPage/SHOW_PROFILE_LISTING_ERROR';

export const FETCH_PROJECT_TRANSACTION_REQUEST =
  'app/TransactionPage/FETCH_PROJECT_TRANSACTION_REQUEST';
export const FETCH_PROJECT_TRANSACTION_SUCCESS =
  'app/TransactionPage/FETCH_PROJECT_TRANSACTION_SUCCESS';
export const FETCH_PROJECT_TRANSACTION_ERROR =
  'app/TransactionPage/FETCH_PROJECT_TRANSACTION_ERROR';

export const SEND_ENQUIRY_REQUEST_LISTING = 'app/ListingPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS_LISTING = 'app/ListingPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR_LISTING = 'app/ListingPage/SEND_ENQUIRY_ERROR';

export const SEND_ENQUIRY_REQUEST_PROJECT = 'app/ProjectListingPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS_PROJECT = 'app/ProjectListingPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR_PROJECT = 'app/ProjectListingPage/SEND_ENQUIRY_ERROR';

export const CHECK_JOB_AS_COMPLETED_REQUEST = 'app/TransactionPage/MARK_JOB_DONE_REQUEST';
export const CHECK_JOB_AS_COMPLETED_SUCCESS = 'app/ListingPage/MARK_JOB_DONE_SUCCESS';
export const CHECK_JOB_AS_COMPLETED_ERROR = 'app/ListingPage/MARK_JOB_DONE_ERROR';

export const SEND_JOB_DONE_RELEASE_FUNDS_REQUEST =
  'app/TransactionPage/SEND_JOB_DONE_RELEASE_FUNDS_REQUEST';
export const SEND_JOB_DONE_RELEASE_FUNDS_SUCCESS =
  'app/TransactionPage/SEND_JOB_DONE_RELEASE_FUNDS_SUCCESS';
export const SEND_JOB_DONE_RELEASE_FUNDS_ERROR =
  'app/TransactionPage/SEND_JOB_DONE_RELEASE_FUNDS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  transactionRef: null,
  acceptInProgress: false,
  acceptSaleError: null,
  declineInProgress: false,
  declineSaleError: null,
  fetchMessagesInProgress: false,
  fetchMessagesError: null,
  totalMessages: 0,
  totalMessagePages: 0,
  oldestMessagePageFetched: 0,
  messages: [],
  initialMessageFailedToTransaction: null,
  savePaymentMethodFailed: false,
  sendMessageInProgress: false,
  sendMessageError: null,
  sendReviewInProgress: false,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  fetchTransitionsInProgress: false,
  fetchTransitionsError: null,
  processTransitions: null,
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  releaseFundsInProgress: false,
  releaseFundsError: null,
  sendCustomOfferInProgress: false,
  sendCustomOfferError: null,
  acceptCustomOfferInProgress: false,
  acceptCustomOfferError: null,
  declineProjectBidInProgress: false,
  declineProjectBidError: null,
  sendProjectBidInProgress: false,
  sendProjectBidError: null,
  profileListing: null,
  showProfileListingInProgress: false,
  showProfileListingError: false,
  fetchProjectTransactionInProgress: false,
  fetchProjectTransactionError: null,
  projectTransactionRef: null,
  sendEnquiryInProgressListing: false,
  sendEnquiryErrorListing: null,
  sendEnquiryInProgressProject: false,
  sendEnquiryErrorProject: null,
  enquiryModalOpenForListingId: null,
  checkJobAsCompleted: false,
  jobDoneReleaseFundsInProgress: false,
  jobDoneReleaseFundsError: null,
};

// Merge entity arrays using ids, so that conflicting items in newer array (b) overwrite old values (a).
// const a = [{ id: { uuid: 1 } }, { id: { uuid: 3 } }];
// const b = [{ id: : { uuid: 2 } }, { id: : { uuid: 1 } }];
// mergeEntityArrays(a, b)
// => [{ id: { uuid: 3 } }, { id: : { uuid: 2 } }, { id: : { uuid: 1 } }]
const mergeEntityArrays = (a, b) => {
  return a.filter(aEntity => !b.find(bEntity => aEntity.id.uuid === bEntity.id.uuid)).concat(b);
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_TRANSACTION_REQUEST:
      return { ...state, fetchTransactionInProgress: true, fetchTransactionError: null };
    case FETCH_TRANSACTION_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };
      return { ...state, fetchTransactionInProgress: false, transactionRef };
    }
    case FETCH_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransactionInProgress: false, fetchTransactionError: payload };

    case FETCH_TRANSITIONS_REQUEST:
      return { ...state, fetchTransitionsInProgress: true, fetchTransitionsError: null };
    case FETCH_TRANSITIONS_SUCCESS:
      return { ...state, fetchTransitionsInProgress: false, processTransitions: payload };
    case FETCH_TRANSITIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransitionsInProgress: false, fetchTransitionsError: payload };

    case ACCEPT_SALE_REQUEST:
      return { ...state, acceptInProgress: true, acceptSaleError: null, declineSaleError: null };
    case ACCEPT_SALE_SUCCESS:
      return { ...state, acceptInProgress: false };
    case ACCEPT_SALE_ERROR:
      return { ...state, acceptInProgress: false, acceptSaleError: payload };

    case DECLINE_SALE_REQUEST:
      return { ...state, declineInProgress: true, declineSaleError: null, acceptSaleError: null };
    case DECLINE_SALE_SUCCESS:
      return { ...state, declineInProgress: false };
    case DECLINE_SALE_ERROR:
      return { ...state, declineInProgress: false, declineSaleError: payload };

    case FETCH_MESSAGES_REQUEST:
      return { ...state, fetchMessagesInProgress: true, fetchMessagesError: null };
    case FETCH_MESSAGES_SUCCESS: {
      const oldestMessagePageFetched =
        state.oldestMessagePageFetched > payload.page
          ? state.oldestMessagePageFetched
          : payload.page;
      return {
        ...state,
        fetchMessagesInProgress: false,
        messages: mergeEntityArrays(state.messages, payload.messages),
        totalMessages: payload.totalItems,
        totalMessagePages: payload.totalPages,
        oldestMessagePageFetched,
      };
    }
    case FETCH_MESSAGES_ERROR:
      return { ...state, fetchMessagesInProgress: false, fetchMessagesError: payload };

    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        sendMessageInProgress: true,
        sendMessageError: null,
        initialMessageFailedToTransaction: null,
      };
    case SEND_MESSAGE_SUCCESS:
      return { ...state, sendMessageInProgress: false };
    case SEND_MESSAGE_ERROR:
      return { ...state, sendMessageInProgress: false, sendMessageError: payload };

    case SEND_REVIEW_REQUEST:
      return { ...state, sendReviewInProgress: true, sendReviewError: null };
    case SEND_REVIEW_SUCCESS:
      return { ...state, sendReviewInProgress: false };
    case SEND_REVIEW_ERROR:
      return { ...state, sendReviewInProgress: false, sendReviewError: payload };

    case FETCH_TIME_SLOTS_REQUEST:
      return { ...state, fetchTimeSlotsError: null };
    case FETCH_TIME_SLOTS_SUCCESS:
      return { ...state, timeSlots: payload };
    case FETCH_TIME_SLOTS_ERROR:
      return { ...state, fetchTimeSlotsError: payload };

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case RELEASE_FUNDS_REQUEST:
      return { ...state, releaseFundsInProgress: true, releaseFundsError: null };
    case RELEASE_FUNDS_SUCCESS:
      return { ...state, releaseFundsInProgress: false };
    case RELEASE_FUNDS_ERROR:
      return { ...state, releaseFundsInProgress: false, releaseFundsError: payload };

    case SEND_CUSTOM_OFFER_REQUEST:
      return { ...state, sendCustomOfferInProgress: true, sendCustomOfferError: null };
    case SEND_CUSTOM_OFFER_SUCCESS:
      return { ...state, sendCustomOfferInProgress: false };
    case SEND_CUSTOM_OFFER_ERROR:
      return { ...state, sendCustomOfferInProgress: false, sendCustomOfferError: payload };

    case ACCEPT_CUSTOM_OFFER_REQUEST:
      return { ...state, acceptCustomOfferInProgress: true, acceptCustomOfferError: null };
    case ACCEPT_CUSTOM_OFFER_SUCCESS:
      return { ...state, acceptCustomOfferInProgress: false };
    case ACCEPT_CUSTOM_OFFER_ERROR:
      return { ...state, acceptCustomOfferInProgress: false, acceptCustomOfferError: payload };

    case DECLINE_PROJECT_BID_REQUEST:
      return { ...state, declineProjectBidInProgress: true, declineProjectBidError: null };
    case DECLINE_PROJECT_BID_SUCCESS:
      return { ...state, declineProjectBidInProgress: false };
    case DECLINE_PROJECT_BID_ERROR:
      return { ...state, declineProjectBidInProgress: false, declineProjectBidError: payload };

    case SEND_PROJECT_BID_REQUEST:
      return { ...state, sendProjectBidInProgress: true, sendProjectBidError: null };
    case SEND_PROJECT_BID_SUCCESS:
      return { ...state, sendProjectBidInProgress: false };
    case SEND_PROJECT_BID_ERROR:
      return { ...state, sendProjectBidInProgress: false, sendProjectBidError: payload };

    case SHOW_PROFILE_LISTING_REQUEST:
      return {
        ...state,
        showProfileListingInProgress: true,
        showProfileListingError: null,
        profileListing: null,
      };
    case SHOW_PROFILE_LISTING_SUCCESS:
      return { ...state, showProfileListingInProgress: false, profileListing: payload };
    case SHOW_PROFILE_LISTING_ERROR:
      return { ...state, showProfileListingInProgress: false, showProfileListingError: payload };

    case FETCH_PROJECT_TRANSACTION_REQUEST:
      return {
        ...state,
        fetchProjectTransactionInProgress: true,
        fetchProjectTransactionError: null,
      };
    case FETCH_PROJECT_TRANSACTION_SUCCESS: {
      const projectTransactionRef = { id: payload.data.data.id, type: 'transaction' };
      return { ...state, fetchProjectTransactionInProgress: false, projectTransactionRef };
    }
    case FETCH_PROJECT_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        fetchProjectTransactionInProgress: false,
        fetchProjectTransactionError: payload,
      };

    case SEND_ENQUIRY_REQUEST_LISTING:
      return { ...state, sendEnquiryInProgressListing: true, sendEnquiryErrorListing: null };
    case SEND_ENQUIRY_SUCCESS_LISTING:
      return { ...state, sendEnquiryInProgressListing: false };
    case SEND_ENQUIRY_ERROR_LISTING:
      return { ...state, sendEnquiryInProgressListing: false, sendEnquiryErrorListing: payload };

    case SEND_ENQUIRY_REQUEST_PROJECT:
      return { ...state, sendEnquiryInProgressProject: true, sendEnquiryErrorProject: null };
    case SEND_ENQUIRY_SUCCESS_PROJECT:
      return { ...state, sendEnquiryInProgressProject: false };
    case SEND_ENQUIRY_ERROR_PROJECT:
      return { ...state, sendEnquiryInProgressProject: false, sendEnquiryErrorProject: payload };

    case CHECK_JOB_AS_COMPLETED_SUCCESS:
      return { ...state, checkJobAsCompleted: true };

    case SEND_JOB_DONE_RELEASE_FUNDS_REQUEST:
      return { ...state, jobDoneReleaseFundsInProgress: true, jobDoneReleaseFundsError: null };
    case SEND_JOB_DONE_RELEASE_FUNDS_SUCCESS:
      return { ...state, jobDoneReleaseFundsInProgress: false, jobDoneReleaseFundsError: null };
    case SEND_JOB_DONE_RELEASE_FUNDS_ERROR:
      return { ...state, jobDoneReleaseFundsInProgress: false, jobDoneReleaseFundsError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const acceptOrDeclineInProgress = state => {
  return state.TransactionPage.acceptInProgress || state.TransactionPage.declineInProgress;
};

export const releaseFundsInProgress = state => {
  return state.TransactionPage.releaseFundsInProgress;
};

export const sendCustomOfferInProgress = state => {
  return state.TransactionPage.sendCustomOfferInProgress;
};

export const acceptCustomOfferInProgress = state => {
  return state.TransactionPage.acceptCustomOfferInProgress;
};

export const declineProjectBidInProgress = state => {
  return state.TransactionPage.declineProjectBidInProgress;
};

// ================ Action creators ================ //
export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = response => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchTransactionError = e => ({ type: FETCH_TRANSACTION_ERROR, error: true, payload: e });

const fetchTransitionsRequest = () => ({ type: FETCH_TRANSITIONS_REQUEST });
const fetchTransitionsSuccess = response => ({
  type: FETCH_TRANSITIONS_SUCCESS,
  payload: response,
});
const fetchTransitionsError = e => ({ type: FETCH_TRANSITIONS_ERROR, error: true, payload: e });

const acceptSaleRequest = () => ({ type: ACCEPT_SALE_REQUEST });
const acceptSaleSuccess = () => ({ type: ACCEPT_SALE_SUCCESS });
const acceptSaleError = e => ({ type: ACCEPT_SALE_ERROR, error: true, payload: e });

const declineSaleRequest = () => ({ type: DECLINE_SALE_REQUEST });
const declineSaleSuccess = () => ({ type: DECLINE_SALE_SUCCESS });
const declineSaleError = e => ({ type: DECLINE_SALE_ERROR, error: true, payload: e });

const fetchMessagesRequest = () => ({ type: FETCH_MESSAGES_REQUEST });
const fetchMessagesSuccess = (messages, pagination) => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: { messages, ...pagination },
});
const fetchMessagesError = e => ({ type: FETCH_MESSAGES_ERROR, error: true, payload: e });

const sendMessageRequest = () => ({ type: SEND_MESSAGE_REQUEST });
const sendMessageSuccess = () => ({ type: SEND_MESSAGE_SUCCESS });
const sendMessageError = e => ({ type: SEND_MESSAGE_ERROR, error: true, payload: e });

const sendReviewRequest = () => ({ type: SEND_REVIEW_REQUEST });
const sendReviewSuccess = () => ({ type: SEND_REVIEW_SUCCESS });
const sendReviewError = e => ({ type: SEND_REVIEW_ERROR, error: true, payload: e });

const fetchTimeSlotsRequest = () => ({ type: FETCH_TIME_SLOTS_REQUEST });
const fetchTimeSlotsSuccess = timeSlots => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: timeSlots,
});
const fetchTimeSlotsError = e => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: e,
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

const releaseFundsRequest = () => ({ type: RELEASE_FUNDS_REQUEST });
const releaseFundsSuccess = () => ({ type: RELEASE_FUNDS_SUCCESS });
const releaseFundsError = e => ({ type: RELEASE_FUNDS_ERROR, error: true, payload: e });

const sendCustomOfferRequest = () => ({ type: SEND_CUSTOM_OFFER_REQUEST });
const sendCustomOfferSuccess = () => ({ type: SEND_CUSTOM_OFFER_SUCCESS });
const sendCustomOfferError = e => ({ type: SEND_CUSTOM_OFFER_ERROR, error: true, payload: e });

const declineProjectBidRequest = () => ({ type: DECLINE_PROJECT_BID_REQUEST });
const declineProjectBidSuccess = () => ({ type: DECLINE_PROJECT_BID_SUCCESS });
const declineProjectBidError = e => ({ type: DECLINE_PROJECT_BID_ERROR, error: true, payload: e });

const sendProjectBidRequest = () => ({ type: SEND_PROJECT_BID_REQUEST });
const sendProjectBidSuccess = () => ({ type: SEND_PROJECT_BID_SUCCESS });
const sendProjectBidError = e => ({ type: SEND_PROJECT_BID_ERROR, error: true, payload: e });

const showProfileListingRequest = () => ({ type: SHOW_PROFILE_LISTING_REQUEST });
const showProfileListingSuccess = pl => ({ type: SHOW_PROFILE_LISTING_SUCCESS, payload: pl });
const showProfileListingError = e => ({
  type: SHOW_PROFILE_LISTING_ERROR,
  error: true,
  payload: e,
});

const fetchProjectTransactionRequest = () => ({ type: FETCH_PROJECT_TRANSACTION_REQUEST });
const fetchProjectTransactionSuccess = response => ({
  type: FETCH_PROJECT_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchProjectTransactionError = e => ({
  type: FETCH_PROJECT_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST_LISTING });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS_LISTING });
export const sendEnquiryError = e => ({
  type: SEND_ENQUIRY_ERROR_LISTING,
  error: true,
  payload: e,
});

export const checkJobAsCompletedRequest = () => ({ type: CHECK_JOB_AS_COMPLETED_REQUEST });
export const checkJobAsCompletedSuccess = () => ({ type: CHECK_JOB_AS_COMPLETED_SUCCESS });
export const checkJobAsCompletedError = e => ({
  type: CHECK_JOB_AS_COMPLETED_ERROR,
  error: true,
  payload: e,
});

export const jobDoneReleaseFundsRequest = () => ({ type: SEND_JOB_DONE_RELEASE_FUNDS_REQUEST });
export const jobDoneReleaseFundsSuccess = () => ({ type: SEND_JOB_DONE_RELEASE_FUNDS_SUCCESS });
export const jobDoneReleaseFundsError = e => ({
  type: SEND_JOB_DONE_RELEASE_FUNDS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const listingRelationship = txResponse => {
  return txResponse.data.data.relationships.listing.data;
};

export const fetchTransaction = (id, txRole) => (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest());
  let txResponse = null;

  return sdk.transactions
    .show(
      {
        id,
        include: [
          'customer',
          'customer.profileImage',
          'provider',
          'provider.profileImage',
          'listing',
          'booking',
          'reviews',
          'reviews.author',
          'reviews.subject',
        ],
        ...IMAGE_VARIANTS,
      },
      { expand: true }
    )
    .then(response => {
      txResponse = response;
      const listingId = listingRelationship(response).id;
      const entities = updatedEntities({}, response.data);
      const listingRef = { id: listingId, type: 'listing' };
      const transactionRef = { id, type: 'transaction' };
      const denormalised = denormalisedEntities(entities, [listingRef, transactionRef]);
      const listing = denormalised[0];
      const transaction = denormalised[1];

      // Fetch time slots for transactions that are in enquired state
      const canFetchTimeslots =
        txRole === 'customer' &&
        config.enableAvailability &&
        transaction &&
        txIsEnquired(transaction);

      if (canFetchTimeslots) {
        dispatch(fetchTimeSlots(listingId));
      }

      if (
        transaction.attributes.protectedData &&
        transaction.attributes.protectedData.projectTransactionId
      ) {
        dispatch(
          fetchProjectTransaction(
            new UUID(transaction.attributes.protectedData.projectTransactionId)
          )
        );
      }

      const canFetchListing = listing && listing.attributes && !listing.attributes.deleted;
      if (canFetchListing) {
        return sdk.listings.show({
          id: listingId,
          include: ['author', 'author.profileImage', 'images'],
          ...IMAGE_VARIANTS,
        });
      } else {
        return response;
      }
    })
    .then(response => {
      dispatch(addMarketplaceEntities(txResponse));
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchTransactionSuccess(txResponse));
      return response;
    })
    .catch(e => {
      dispatch(fetchTransactionError(storableError(e)));
      throw e;
    });
};

export const fetchProjectTransaction = id => (dispatch, getState, sdk) => {
  dispatch(fetchProjectTransactionRequest());
  let txResponse = null;

  return sdk.transactions
    .show(
      {
        id,
        include: [
          'customer',
          'customer.profileImage',
          'provider',
          'provider.profileImage',
          'listing',
          'booking',
          'reviews',
          'reviews.author',
          'reviews.subject',
        ],
        ...IMAGE_VARIANTS,
      },
      { expand: true }
    )
    .then(response => {
      txResponse = response;
      const listingId = listingRelationship(response).id;
      const entities = updatedEntities({}, response.data);
      const listingRef = { id: listingId, type: 'listing' };
      const transactionRef = { id, type: 'transaction' };
      const denormalised = denormalisedEntities(entities, [listingRef, transactionRef]);
      const listing = denormalised[0];

      const canFetchListing = listing && listing.attributes && !listing.attributes.deleted;
      if (canFetchListing) {
        return sdk.listings.show({
          id: listingId,
          include: ['author', 'author.profileImage', 'images'],
          ...IMAGE_VARIANTS,
        });
      } else {
        return response;
      }
    })
    .then(response => {
      dispatch(addMarketplaceEntities(txResponse));
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchProjectTransactionSuccess(txResponse));
      return response;
    })
    .catch(e => {
      dispatch(fetchProjectTransactionError(storableError(e)));
      throw e;
    });
};

export const acceptSale = id => (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(acceptSaleRequest());

  return sdk.transactions
    .transition(
      { id, transition: TRANSITION_ACCEPT, params: {} },
      { expand: true, include: ['customer', 'listing'] }
    )
    .then(response => {
      const transaction = response.data.data;
      const transactionId = transaction.id;
      const customer = transaction.relationships.customer.data;
      const { protectedData = {} } = transaction.attributes;
      const { lePayoutTransactionId } = protectedData;

      // Send the message to the created transaction
      return markTxUnread({
        transactionId: transactionId.uuid,
        recipientId: customer.id.uuid,
      }).then(() => {
        dispatch(addMarketplaceEntities(response));
        dispatch(acceptSaleSuccess());
        dispatch(fetchCurrentUserNotifications());

        if (lePayoutTransactionId) {
          return completePayoutTransaction(lePayoutTransactionId).then(() => {
            return response;
          });
        }

        return response;
      });
    })
    .catch(e => {
      dispatch(acceptSaleError(storableError(e)));
      log.error(e, 'accept-sale-failed', {
        txId: id,
        transition: TRANSITION_ACCEPT,
      });
      throw e;
    });
};

export const jobReady = id => async (dispatch, getState, sdk) => {
  dispatch(checkJobAsCompletedRequest());

  return sdk.transactions
    .transition(
      { id, transition: TRANSITION_JOB_DONE, params: {} },
      { expand: true, include: ['customer', 'listing'] }
    )
    .then(async response => {
      const transaction = response.data.data;
      const transactionId = transaction.id;
      const customer = transaction.relationships.customer.data;
      const { protectedData = {} } = transaction.attributes;
      const { lePayoutTransactionId } = protectedData;

      // Send the message to the created transaction
      await markTxUnread({
        transactionId: transactionId.uuid,
        recipientId: customer.id.uuid,
      });
      dispatch(addMarketplaceEntities(response));
      dispatch(checkJobAsCompletedSuccess());
      dispatch(fetchCurrentUserNotifications());
      if (lePayoutTransactionId) {
        return completePayoutTransaction(lePayoutTransactionId).then(() => {
          return response;
        });
      }
      return response;
    })
    .catch(e => {
      dispatch(checkJobAsCompletedError(storableError(e)));
      log.error(e, 'job-done-failed', {
        txId: id,
        transition: TRANSITION_JOB_DONE,
      });
      throw e;
    });
};

export const jobReadyCustomer = id => async (dispatch, getState, sdk) => {
  dispatch(checkJobAsCompletedRequest());

  return sdk.transactions
    .transition(
      { id, transition: TRANSITION_JOB_DONE_CUSTOMER, params: {} },
      { expand: true, include: ['provider', 'listing'] }
    )
    .then(async response => {
      const transaction = response.data.data;
      const transactionId = transaction.id;
      const provider = transaction.relationships.provider.data;
      const { protectedData = {} } = transaction.attributes;
      const { lePayoutTransactionId } = protectedData;

      // Send the message to the created transaction
      await markTxUnread({
        transactionId: transactionId.uuid,
        recipientId: provider.id.uuid,
      });
      dispatch(addMarketplaceEntities(response));
      dispatch(checkJobAsCompletedSuccess());
      dispatch(fetchCurrentUserNotifications());
      if (lePayoutTransactionId) {
        return completePayoutTransaction(lePayoutTransactionId).then(() => {
          return response;
        });
      }
      return response;
    })
    .catch(e => {
      dispatch(checkJobAsCompletedError(storableError(e)));
      log.error(e, 'job-done-failed', {
        txId: id,
        transition: TRANSITION_JOB_DONE_CUSTOMER,
      });
      throw e;
    });
};

export const declineSale = id => (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(declineSaleRequest());

  return sdk.transactions
    .transition(
      { id, transition: TRANSITION_DECLINE, params: {} },
      { expand: true, include: ['customer'] }
    )
    .then(response => {
      const transaction = response.data.data;
      const transactionId = transaction.id;
      const customer = transaction.relationships.customer.data;
      const { protectedData = {} } = transaction.attributes;
      const { lePayoutTransactionId } = protectedData;

      // Send the message to the created transaction
      return markTxUnread({
        transactionId: transactionId.uuid,
        recipientId: customer.id.uuid,
      }).then(() => {
        dispatch(addMarketplaceEntities(response));
        dispatch(declineSaleSuccess());
        dispatch(fetchCurrentUserNotifications());

        if (lePayoutTransactionId) {
          return cancelPayoutTransaction(lePayoutTransactionId).then(() => {
            return response;
          });
        }

        return response;
      });
    })
    .catch(e => {
      dispatch(declineSaleError(storableError(e)));
      log.error(e, 'reject-sale-failed', {
        txId: id,
        transition: TRANSITION_DECLINE,
      });
      throw e;
    });
};

export const releaseFunds = id => (dispatch, getState, sdk) => {
  if (releaseFundsInProgress(getState())) {
    return Promise.reject(new Error('Release funds already in progress'));
  }
  dispatch(releaseFundsRequest());

  const payoutInvoiceDate = new Date();

  return sdk.transactions
    .transition(
      {
        id,
        transition: TRANSITION_RELEASE_FUNDS,
        params: {
          protectedData: {
            payoutInvoiceDate: {
              year: payoutInvoiceDate.getFullYear(),
              month: payoutInvoiceDate.getMonth() + 1,
              day: payoutInvoiceDate.getDate(),
              hours: 0,
              minutes: 0,
              seconds: 0,
              milliseconds: 0,
            },
          },
        },
      },
      { expand: true, include: ['provider', 'customer', 'listing'] }
    )
    .then(response => {
      const tx = response.data.data;
      const { protectedData = {} } = tx.attributes;
      const { lePayoutTransactionId } = protectedData;

      dispatch(addMarketplaceEntities(response));
      dispatch(releaseFundsSuccess());
      dispatch(fetchCurrentUserNotifications());

      if (lePayoutTransactionId) {
        return sdk.transactions
          .transition(
            {
              id: new UUID(lePayoutTransactionId),
              transition: TRANSITION_RELEASE_FUNDS,
              params: {},
            },
            { expand: true }
          )
          .then(() => {
            return response;
          });
      }

      return response;
    })
    .then(response => {
      const transaction = response.data.data;
      const transactionId = transaction.id;
      const provider = transaction.relationships.provider.data;

      // Send the message to the created transaction
      return markTxUnread({
        transactionId: transactionId.uuid,
        recipientId: provider.id.uuid,
      }).then(() => {
        return response;
      });
    })
    .then(response => {
      if (typeof window === 'object') {
        window.Intercom('trackEvent', 'Funds released', {
          transactionId: response.data.data.id.uuid,
          processName: response.data.data.attributes.processName,
          providerId: response.data.data.relationships.provider.data.id.uuid,
          customerId: response.data.data.relationships.customer.data.id.uuid,
          transition: 'TRANSITION_RELEASE_FUNDS',
          listingType: response.data.data.attributes.protectedData.projectTransactionId
            ? 'project'
            : 'service',
          listingId: response.data.data.relationships.listing.data.id.uuid,
        });
      }
      return response;
    })
    .catch(e => {
      dispatch(releaseFundsError(storableError(e)));
      log.error(e, 'release-funds-failed', {
        txId: id,
        transition: TRANSITION_RELEASE_FUNDS,
      });
      throw e;
    });
};

export const sendCustomOffer = (id, description, customOfferAmount, intl, deliveryDate) => (
  dispatch,
  getState,
  sdk
) => {
  if (sendCustomOfferInProgress(getState())) {
    return Promise.reject(new Error('Send custom offer is already in progress'));
  }
  dispatch(sendCustomOfferRequest());

  return sdk.transactions
    .transition(
      {
        id,
        transition: TRANSITION_MAKE_CUSTOM_OFFER,
        params: {
          protectedData: {
            customOfferDescription: description,
            customOfferAmount: customOfferAmount.amount,
            customOfferFormattedAmount: formatMoney(intl, customOfferAmount),
            customOfferDeliveryDate: stringifyDateToISO8601(deliveryDate.date),
          },
        },
      },
      { expand: true, include: ['provider', 'customer', 'listing'] }
    )
    .then(response => {
      const customOfferMessage = intl.formatMessage(
        { id: 'TransactionPage.customOfferMessage' },
        {
          customOfferAmount: formatMoney(intl, customOfferAmount),
          customOfferDescription: description,
          customOfferDeliveryDate: intl.formatDate(deliveryDate.date),
        }
      );

      dispatch(sendMessage(id, customOfferMessage));
      dispatch(addMarketplaceEntities(response));
      dispatch(sendCustomOfferSuccess());
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .then(response => {
      if (typeof window === 'object') {
        window.Intercom('trackEvent', 'New custom offer', {
          transactionId: response.data.data.id.uuid,
          processName: response.data.data.attributes.processName,
          providerId: response.data.data.relationships.provider.data.id.uuid,
          customerId: response.data.data.relationships.customer.data.id.uuid,
          transition: 'TRANSITION_MAKE_CUSTOM_OFFER',
          listingType: 'service',
          listingId: response.data.data.relationships.listing.data.id.uuid,
        });
      }
      return response;
    })
    .catch(e => {
      dispatch(sendCustomOfferError(storableError(e)));
      log.error(e, 'make-custom-offer-failed', {
        txId: id,
        transition: TRANSITION_ACCEPT,
      });
      throw e;
    });
};

const fetchMessages = (txId, page) => (dispatch, getState, sdk) => {
  const paging = { page, per_page: MESSAGES_PAGE_SIZE };
  dispatch(fetchMessagesRequest());

  return sdk.messages
    .query({
      transaction_id: txId,
      include: ['sender', 'sender.profileImage'],
      ...IMAGE_VARIANTS,
      ...paging,
    })
    .then(response => {
      const messages = denormalisedResponseEntities(response);
      const { totalItems, totalPages, page: fetchedPage } = response.data.meta;
      const pagination = { totalItems, totalPages, page: fetchedPage };
      const totalMessages = getState().TransactionPage.totalMessages;

      // Original fetchMessages call succeeded
      dispatch(fetchMessagesSuccess(messages, pagination));

      // Check if totalItems has changed between fetched pagination pages
      // if totalItems has changed, fetch first page again to include new incoming messages.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      if (totalItems > totalMessages && page > 1) {
        dispatch(fetchMessages(txId, 1))
          .then(() => {
            // Original fetch was enough as a response for user action,
            // this just includes new incoming messages
          })
          .catch(() => {
            // Background update, no need to to do anything atm.
          });
      }
    })
    .catch(e => {
      dispatch(fetchMessagesError(storableError(e)));
      throw e;
    });
};

export const fetchMoreMessages = txId => (dispatch, getState, sdk) => {
  const state = getState();
  const { oldestMessagePageFetched, totalMessagePages } = state.TransactionPage;
  const hasMoreOldMessages = totalMessagePages > oldestMessagePageFetched;

  // In case there're no more old pages left we default to fetching the current cursor position
  const nextPage = hasMoreOldMessages ? oldestMessagePageFetched + 1 : oldestMessagePageFetched;

  return dispatch(fetchMessages(txId, nextPage));
};

export const sendMessage = (txId, message) => (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;

  dispatch(sendMessageRequest());

  return sdk.messages
    .send({ transactionId: txId, content: message }, { expand: true })
    .then(response => {
      const messageId = response.data.data.id;
      const messageData = response.data.data;

      // We fetch the first page again to add sent message to the page data
      // and update possible incoming messages too.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      return dispatch(fetchMessages(txId, 1))
        .then(() => {
          return sdk.transactions.show(
            { id: txId, include: ['customer', 'provider'] },
            { expand: true }
          );
        })
        .then(response => {
          const transaction = response.data.data;
          const transactionId = transaction.id;
          const customer = transaction.relationships.customer.data;
          const provider = transaction.relationships.provider.data;
          const isCustomer = currentUser.id.uuid === customer.id.uuid;
          const recipientId = isCustomer ? provider.id : customer.id;

          // Send the message to the created transaction
          return markTxUnread({
            transactionId: transactionId.uuid,
            recipientId: recipientId.uuid,
            message: messageData,
          });
        })
        .then(() => {
          dispatch(sendMessageSuccess());
          return messageId;
        })
        .catch(() => dispatch(sendMessageSuccess()));
    })
    .catch(e => {
      dispatch(sendMessageError(storableError(e)));
      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

const REVIEW_TX_INCLUDES = ['reviews', 'reviews.author', 'reviews.subject', 'customer', 'provider'];
const IMAGE_VARIANTS = {
  'fields.image': [
    // Profile images
    'variants.square-small',
    'variants.square-small2x',

    // Listing images:
    'variants.landscape-crop',
    'variants.landscape-crop2x',
  ],
};

// If other party has already sent a review, we need to make transition to
// TRANSITION_REVIEW_2_BY_<CUSTOMER/PROVIDER>
const sendReviewAsSecond = (id, params, role, dispatch, sdk) => {
  const transition = getReview2Transition(role === CUSTOMER);

  const include = REVIEW_TX_INCLUDES;

  return sdk.transactions
    .transition({ id, transition, params }, { expand: true, include, ...IMAGE_VARIANTS })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(sendReviewSuccess());
      return response;
    })
    .then(response => {
      const transaction = response.data.data;
      const transactionId = transaction.id;
      const customer = transaction.relationships.customer.data;
      const provider = transaction.relationships.provider.data;
      const isCustomer = role === CUSTOMER;
      const recipientId = isCustomer ? provider.id : customer.id;

      // Send the message to the created transaction
      return markTxUnread({
        transactionId: transactionId.uuid,
        recipientId: recipientId.uuid,
      }).then(() => {
        return response;
      });
    })
    .catch(e => {
      dispatch(sendReviewError(storableError(e)));

      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

// If other party has not yet sent a review, we need to make transition to
// TRANSITION_REVIEW_1_BY_<CUSTOMER/PROVIDER>
// However, the other party might have made the review after previous data synch point.
// So, error is likely to happen and then we must try another state transition
// by calling sendReviewAsSecond().
const sendReviewAsFirst = (id, params, role, dispatch, sdk) => {
  const transition = getReview1Transition(role === CUSTOMER);
  const include = REVIEW_TX_INCLUDES;

  return sdk.transactions
    .transition({ id, transition, params }, { expand: true, include, ...IMAGE_VARIANTS })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(sendReviewSuccess());
      return response;
    })
    .then(response => {
      const transaction = response.data.data;
      const transactionId = transaction.id;
      const customer = transaction.relationships.customer.data;
      const provider = transaction.relationships.provider.data;
      const isCustomer = role === CUSTOMER;
      const recipientId = isCustomer ? provider.id : customer.id;

      // Send the message to the created transaction
      return markTxUnread({
        transactionId: transactionId.uuid,
        recipientId: recipientId.uuid,
      }).then(() => {
        return response;
      });
    })
    .catch(e => {
      // If transaction transition is invalid, lets try another endpoint.
      if (isTransactionsTransitionInvalidTransition(e)) {
        return sendReviewAsSecond(id, params, role, dispatch, sdk);
      } else {
        dispatch(sendReviewError(storableError(e)));

        // Rethrow so the page can track whether the sending failed, and
        // keep the message in the form for a retry.
        throw e;
      }
    });
};

export const sendReview = (role, tx, reviewRating, reviewContent) => (dispatch, getState, sdk) => {
  const params = { reviewRating, reviewContent };

  const txStateOtherPartyFirst = txIsInFirstReviewBy(tx, role !== CUSTOMER);

  dispatch(sendReviewRequest());

  return txStateOtherPartyFirst
    ? sendReviewAsSecond(tx.id, params, role, dispatch, sdk)
    : sendReviewAsFirst(tx.id, params, role, dispatch, sdk);
};

const isNonEmpty = value => {
  return typeof value === 'object' || Array.isArray(value) ? !isEmpty(value) : !!value;
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

const fetchTimeSlots = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchTimeSlotsRequest);

  // Time slots can be fetched for 90 days at a time,
  // for at most 180 days from now. If max number of bookable
  // day exceeds 90, a second request is made.

  const maxTimeSlots = 90;
  // booking range: today + bookable days -1
  const bookingRange = config.dayCountAvailableForBooking - 1;
  const timeSlotsRange = Math.min(bookingRange, maxTimeSlots);

  const start = moment
    .utc()
    .startOf('day')
    .toDate();
  const end = moment()
    .utc()
    .startOf('day')
    .add(timeSlotsRange, 'days')
    .toDate();
  const params = { listingId, start, end };

  return dispatch(timeSlotsRequest(params))
    .then(timeSlots => {
      const secondRequest = bookingRange > maxTimeSlots;

      if (secondRequest) {
        const secondRange = Math.min(maxTimeSlots, bookingRange - maxTimeSlots);
        const secondParams = {
          listingId,
          start: end,
          end: moment(end)
            .add(secondRange, 'days')
            .toDate(),
        };

        return dispatch(timeSlotsRequest(secondParams)).then(secondBatch => {
          const combined = timeSlots.concat(secondBatch);
          dispatch(fetchTimeSlotsSuccess(combined));
        });
      } else {
        dispatch(fetchTimeSlotsSuccess(timeSlots));
      }
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(storableError(e)));
    });
};

export const fetchNextTransitions = id => (dispatch, getState, sdk) => {
  dispatch(fetchTransitionsRequest());

  return sdk.processTransitions
    .query({ transactionId: id })
    .then(res => {
      dispatch(fetchTransitionsSuccess(res.data.data));
    })
    .catch(e => {
      dispatch(fetchTransitionsError(storableError(e)));
    });
};

export const fetchTransactionLineItems = ({ bookingData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ bookingData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        bookingData: bookingData,
      });
    });
};

export const sendProjectBid = (
  listingId,
  transactionId,
  description,
  amount,
  intl,
  deliveryDate
) => (dispatch, getState, sdk) => {
  dispatch(sendProjectBidRequest());

  const bodyParams = {
    id: transactionId,
    transition: TRANSITION_MAKE_PROJECT_BID_AFTER_ENQUIRY,
    params: {
      listingId,
      protectedData: {
        projectBidAmount: amount,
        projectBidDescription: description,
        projectBidDeliveryDate: stringifyDateToISO8601(deliveryDate.date),
      },
    },
  };

  return projectTransitionPrivileged({
    isSpeculative: false,
    bookingData: { description, amount },
    bodyParams,
    queryParams: { expand: true, include: ['provider', 'customer', 'listing'] },
  })
    .then(response => {
      const transaction = response.data.data;
      const transactionId = transaction.id;
      const provider = transaction.relationships.provider.data;

      // Send the message to the created transaction
      return markTxUnread({
        transactionId: transactionId.uuid,
        recipientId: provider.id.uuid,
      }).then(() => {
        return response;
      });
    })
    .then(response => {
      dispatch(sendProjectBidSuccess());
      dispatch(fetchCurrentUserHasOrdersSuccess(true));
      dispatch(fetchTransaction(transactionId, 'customer'));

      return response;
    })
    .then(response => {
      if (typeof window === 'object') {
        window.Intercom('trackEvent', 'New project bid', {
          transactionId: response.data.data.id.uuid,
          processName: response.data.data.attributes.processName,
          providerId: response.data.data.relationships.customer.data.id.uuid,
          customerId: response.data.data.relationships.provider.data.id.uuid,
          transition: 'TRANSITION_MAKE_PROJECT_BID_AFTER_ENQUIRY',
          listingType: 'project',
          listingId: response.data.data.relationships.listing.data.id.uuid,
        });
      }
      return response.data.data.id;
    })
    .catch(e => {
      dispatch(sendProjectBidError(storableError(e)));
      throw e;
    });
};

export const declineProjectBid = id => (dispatch, getState, sdk) => {
  if (declineProjectBidInProgress(getState())) {
    return Promise.reject(new Error('Project bid decline already in progress'));
  }
  dispatch(declineProjectBidRequest());

  return sdk.transactions
    .transition(
      { id, transition: TRANSITION_DECLINE_OFFER, params: {} },
      { expand: true, include: ['customer'] }
    )
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(declineProjectBidSuccess());
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .then(response => {
      const transaction = response.data.data;
      const transactionId = transaction.id;
      const customer = transaction.relationships.customer.data;

      // Mark project transaction as read
      return markTxRead(transactionId.uuid, customer.id.uuid).then(() => {
        return response;
      });
    })
    .catch(e => {
      dispatch(declineProjectBidError(storableError(e)));
      log.error(e, 'reject-project-bid-failed', {
        txId: id,
        transition: TRANSITION_DECLINE_OFFER,
      });
      throw e;
    });
};

export const showProfileListing = userId => (dispatch, getState, sdk) => {
  dispatch(showProfileListingRequest());

  return sdk.listings
    .query({
      authorId: userId,
      pub_listingType: 'profile',
      include: ['author'],
    })
    .then(response => {
      const listings = response.data.data || [];

      if (listings.length >= 1) {
        dispatch(showProfileListingSuccess(listings[0]));
      } else {
        throw new Error('Provider does not have a profile listing created.');
      }

      return denormalisedResponseEntities(response)[0];
    })
    .catch(e => {
      dispatch(showProfileListingError(storableError(e)));
      console.error(e);
    });
};

export const sendEnquiryCustomer = (listingId, isLightEntrepreneur, message) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(sendEnquiryRequest());
  const bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: isLightEntrepreneur ? config.lightProcessAlias : config.bookingProcessAlias,

    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams, { expand: true, include: ['provider'] })
    .then(response => {
      const transaction = response.data.data;
      const transactionId = transaction.id;
      const provider = transaction.relationships.provider.data;
      const recipientId = provider.id;

      return sdk.messages
        .send({ transactionId, content: message }, { expand: true })
        .then(response => {
          const messageData = response.data.data;
          // Send the message to the created transaction
          return markTxUnread({
            transactionId: transactionId.uuid,
            recipientId: recipientId.uuid,
            message: messageData,
          });
        })
        .then(() => {
          dispatch(sendEnquirySuccess());
          dispatch(fetchCurrentUserHasOrdersSuccess(true));

          return transactionId;
        });
    })
    .catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    });
};

export const sendEnquiryProvider = (listingId, message) => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());
  const bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: config.projectProcessAlias,
    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams, { expand: true, include: ['provider'] })
    .then(response => {
      const transaction = response.data.data;
      const transactionId = transaction.id;
      const provider = transaction.relationships.provider.data;
      const recipientId = provider.id;

      return sdk.messages
        .send({ transactionId, content: message }, { expand: true })
        .then(response => {
          const messageData = response.data.data;
          // Send the message to the created transaction
          return markTxUnread({
            transactionId: transactionId.uuid,
            recipientId: recipientId.uuid,
            message: messageData,
          });
        })
        .then(() => {
          dispatch(sendEnquirySuccess());
          dispatch(fetchCurrentUserHasOrdersSuccess(true));

          return transactionId;
        });
    })
    .catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    });
};

export const jobDoneReleaseFunds = id => (dispatch, getState, sdk) => {
  dispatch(jobDoneReleaseFundsRequest());

  const payoutInvoiceDate = new Date();

  return sdk.transactions
    .transition(
      {
        id,
        transition: TRANSITION_JOB_DONE_RELEASE_FUNDS,
        params: {
          protectedData: {
            payoutInvoiceDate: {
              year: payoutInvoiceDate.getFullYear(),
              month: payoutInvoiceDate.getMonth() + 1,
              day: payoutInvoiceDate.getDate(),
              hours: 0,
              minutes: 0,
              seconds: 0,
              milliseconds: 0,
            },
          },
        },
      },
      { expand: true, include: ['provider', 'customer', 'listing'] }
    )
    .then(response => {
      const tx = response.data.data;
      const { protectedData = {} } = tx.attributes;
      const { lePayoutTransactionId } = protectedData;

      dispatch(addMarketplaceEntities(response));
      dispatch(jobDoneReleaseFundsSuccess());
      dispatch(fetchCurrentUserNotifications());

      if (lePayoutTransactionId) {
        completePayoutTransaction(lePayoutTransactionId).then(() => {
          return sdk.transactions
            .transition(
              {
                id: new UUID(lePayoutTransactionId),
                transition: TRANSITION_RELEASE_FUNDS,
                params: {},
              },
              { expand: true }
            )
            .then(() => {
              return response;
            });
        });
      }

      return response;
    })
    .then(response => {
      const transaction = response.data.data;
      const transactionId = transaction.id;
      const provider = transaction.relationships.provider.data;

      // Send the message to the created transaction
      return markTxUnread({
        transactionId: transactionId.uuid,
        recipientId: provider.id.uuid,
      }).then(() => {
        return response;
      });
    })
    .then(response => {
      if (typeof window === 'object') {
        window.Intercom('trackEvent', 'Job done funds released', {
          transactionId: response.data.data.id.uuid,
          processName: response.data.data.attributes.processName,
          providerId: response.data.data.relationships.provider.data.id.uuid,
          customerId: response.data.data.relationships.customer.data.id.uuid,
          transition: 'TRANSITION_JOB_DONE_RELEASE_FUNDS',
          listingType: response.data.data.attributes.protectedData.projectTransactionId
            ? 'project'
            : 'service',
          listingId: response.data.data.relationships.listing.data.id.uuid,
        });
      }
      return response;
    })
    .catch(e => {
      dispatch(releaseFundsError(storableError(e)));
      log.error(e, 'job-done-release-funds failed', {
        txId: id,
        transition: TRANSITION_JOB_DONE_RELEASE_FUNDS,
      });
      throw e;
    });
};

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = params => async (dispatch, getState) => {
  const txId = new UUID(params.id);
  const currentUser = await dispatch(fetchCurrentUser());
  const currentUserId = currentUser && currentUser.id ? currentUser.id.uuid : '0';
  const state = getState().TransactionPage;
  const txRef = state.transactionRef;
  const txRole = params.transactionRole;

  // In case a transaction reference is found from a previous
  // data load -> clear the state. Otherwise keep the non-null
  // and non-empty values which may have been set from a previous page.
  const initialValues = txRef ? {} : pickBy(state, isNonEmpty);
  dispatch(setInitialValues(initialValues));

  return markTxRead(params.id, currentUserId).then(res => {
    console.log('res', res);
    // Sale / order (i.e. transaction entity in API)
    return Promise.all([
      dispatch(fetchCurrentUserNotifications()),
      dispatch(fetchTransaction(txId, txRole)),
      dispatch(fetchMessages(txId, 1)),
      dispatch(fetchNextTransitions(txId)),
    ]);
  });
};
