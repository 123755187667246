import React from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import { injectIntl } from '../../util/reactIntl';
import {
  Form,
  FieldTextInput,
  SecondaryButton,
  Button,
  FieldSelectModern,
  IconClose,
  InlineTextButton,
  FieldCheckbox,
} from '../../components';
import { required } from '../../util/validators';
import { generateMonthsList, generateYearsList } from '../../util/dates';

import css from './SignupProviderForms.css';

const SignupProviderWorkExperienceForm = props => {
  const requiredMessage = id => required(props.intl.formatMessage({ id }));

  const validateWorkExperience = (fieldSet, dontAddWorkExperience) => {
    if (dontAddWorkExperience) return;

    const fieldsToValidate = ['customerName', 'task', 'description', 'startMonth', 'startYear'];
    const error = {};

    fieldsToValidate.forEach(field => {
      error[field] = requiredMessage(`SignupProviderForms.${field}Required`)(fieldSet[field]);
    });

    if (!fieldSet.jobContinues) {
      error.endMonth = requiredMessage('SignupProviderForms.dateRequired')(fieldSet.endMonth);
      error.endYear = requiredMessage('SignupProviderForms.dateRequired')(fieldSet.endYear);
    }

    return error;
  };

  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      validate={values => {
        const errors = {};
        errors.workExperience = values.workExperience?.map(item =>
          validateWorkExperience(item, values.dontAddWorkExperience)
        );
        return errors;
      }}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          handleSubmit,
          onPrev,
          form,
          intl,
          form: {
            mutators: { push },
          },
          invalid,
          values,
          isModal,
          initialValues,
        } = formRenderProps;

        const classes = classNames(rootClassName || css.root, className);

        const skipStep = values.dontAddWorkExperience;
        const submitDisabled = invalid && !skipStep;

        const changed =
          JSON.stringify(initialValues.workExperience) !== JSON.stringify(values.workExperience);
        const modalSubmitDisabled = invalid || !changed;

        const heading = intl.formatMessage({ id: 'SignupProviderForms.workExperienceHeading' });
        const description = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceDescription',
        });

        const nextButtonText = intl.formatMessage({ id: 'SignupProviderForms.nextButton' });
        const previousButtonText = intl.formatMessage({ id: 'SignupProviderForms.previousButton' });
        const addButtonText = intl.formatMessage({ id: 'SignupProviderForms.addButton' });
        const okButtonText = intl.formatMessage({ id: 'SignupProviderForms.okButton' });

        const customerNameLabel = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceCustomerNameLabel',
        });
        const customerNamePlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceCustomerNamePlaceholder',
        });

        const taskLabel = intl.formatMessage({ id: 'SignupProviderForms.workExperienceTaskLabel' });
        const taskPlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceTaskPlaceholder',
        });

        const startLabel = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceStartLabel',
        });
        const endLabel = intl.formatMessage({ id: 'SignupProviderForms.workExperienceEndLabel' });

        const monthPlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.monthPlaceholder',
        });
        const yearPlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.yearPlaceholder',
        });

        const jobContinuesLabel = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceContinuesLabel',
        });

        const descriptionLabel = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceDescriptionLabel',
        });
        const descriptionPlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceDescriptionPlaceholder',
        });

        const dontAddWorkExperienceLabel = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceDontAddLabel',
        });

        const workExperiencePoint1 = intl.formatMessage({
          id: 'SignupProviderForms.workExperiencePoint1',
        });

        const hideEndDate = index => values.workExperience[index].jobContinues;

        return (
          <div className={classes}>
            <Form className={css.form} onSubmit={values => handleSubmit(values, form)}>
              <h2 className={css.mainHeading}>
                {heading}
                {isModal ? (
                  <div className={css.submitContainer}>
                    <Button
                      rootClassName={css.nextButton}
                      type="Submit"
                      disabled={modalSubmitDisabled}
                    >
                      {okButtonText}
                    </Button>
                  </div>
                ) : null}
              </h2>
              <div className={css.column}>
                <p className={css.description}>{description}</p>
                <FieldArray name="workExperience">
                  {({ fields }) => (
                    <>
                      {fields.map((name, index) => (
                        <div key={name} className={css.form}>
                          <span
                            className={css.alignEnd}
                            onClick={() =>
                              fields?.length === 1 ? fields.update(index, {}) : fields.remove(index)
                            }
                          >
                            <IconClose rootClassName={css.deleteIcon} />
                          </span>

                          <FieldTextInput
                            id={`${name}.customerName`}
                            name={`${name}.customerName`}
                            type="text"
                            label={customerNameLabel}
                            placeholder={customerNamePlaceholder}
                            disabled={values.dontAddWorkExperience}
                          />
                          <FieldTextInput
                            id={`${name}.task`}
                            name={`${name}.task`}
                            type="text"
                            label={taskLabel}
                            placeholder={taskPlaceholder}
                            disabled={values.dontAddWorkExperience}
                          />

                          <div className={css.datesContainer}>
                            <div className={css.datesItem}>
                              <FieldSelectModern
                                id={`${name}.startMonth`}
                                name={`${name}.startMonth`}
                                options={generateMonthsList()}
                                placeholder={monthPlaceholder}
                                label={startLabel}
                                disabled={values.dontAddWorkExperience}
                              />
                              <FieldSelectModern
                                className={css.years}
                                id={`${name}.startYear`}
                                name={`${name}.startYear`}
                                options={generateYearsList(1900)}
                                placeholder={yearPlaceholder}
                                disabled={values.dontAddWorkExperience}
                              />
                            </div>

                            {!hideEndDate(index) && (
                              <div className={css.datesItem}>
                                <FieldSelectModern
                                  id={`${name}.endMonth`}
                                  name={`${name}.endMonth`}
                                  options={generateMonthsList()}
                                  placeholder={monthPlaceholder}
                                  label={endLabel}
                                  disabled={values.dontAddWorkExperience}
                                />
                                <FieldSelectModern
                                  className={css.years}
                                  id={`${name}.endYear`}
                                  name={`${name}.endYear`}
                                  options={generateYearsList(1900, 5)}
                                  placeholder={yearPlaceholder}
                                  disabled={values.dontAddWorkExperience}
                                />
                              </div>
                            )}
                          </div>

                          <FieldCheckbox
                            id={`${name}.jobContinues`}
                            name={`${name}.jobContinues`}
                            label={jobContinuesLabel}
                            defaultValue={false}
                            disabled={values.dontAddWorkExperience}
                          />

                          <FieldTextInput
                            id={`${name}.description`}
                            name={`${name}.description`}
                            className={css.textFieldMedium}
                            type="textarea"
                            label={descriptionLabel}
                            placeholder={descriptionPlaceholder}
                            disabled={values.dontAddWorkExperience}
                          />
                        </div>
                      ))}
                    </>
                  )}
                </FieldArray>
                <div className={css.column}>
                  <InlineTextButton
                    type="button"
                    className={css.addToArray}
                    onClick={() => push('workExperience', {})}
                    disabled={values.dontAddWorkExperience}
                  >
                    {addButtonText}
                  </InlineTextButton>
                  <FieldCheckbox
                    id="dontAddWorkExperience"
                    name="dontAddWorkExperience"
                    label={dontAddWorkExperienceLabel}
                    defaultValue={false}
                  />
                  <ul className={css.pointerList}>
                    <li>{workExperiencePoint1}</li>
                  </ul>
                </div>
              </div>
              <div className={css.submitContainer}>
                {!isModal && (
                  <SecondaryButton rootClassName={css.backButton} onClick={onPrev}>
                    {previousButtonText}
                  </SecondaryButton>
                )}
                {!isModal ? (
                  <Button rootClassName={css.nextButton} type="Submit" disabled={submitDisabled}>
                    {nextButtonText}
                  </Button>
                ) : null}
              </div>
            </Form>
          </div>
        );
      }}
    />
  );
};

SignupProviderWorkExperienceForm.defaultProps = {
  isModal: false,
};

export default injectIntl(SignupProviderWorkExperienceForm);
