import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { EditProjectListingBudgetForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import { NewJobInfoPanel } from '../../components';

import css from './EditProjectListingBudgetPanel.css';

const { Money } = sdkTypes;

const EditProjectListingBudgetPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const price = new Money(100000, 'EUR');

  const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;
  const form = priceCurrencyValid ? (
    <EditProjectListingBudgetForm
      className={css.form}
      initialValues={{
        price,
        priceEstimate: publicData?.priceEstimate,
        projectDuration: publicData?.projectDuration,
      }}
      onSubmit={values => {
        const { price, priceEstimate, projectDuration } = values;
        const updateValues = { price, publicData: { priceEstimate, projectDuration } };

        onSubmit(updateValues);
      }}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
    />
  ) : (
    <div className={css.priceCurrencyInvalid}>
      <FormattedMessage id="EditProjectListingBudgetPanel.listingPriceCurrencyInvalid" />
    </div>
  );

  return (
    <div className={classes}>
      {/* <h1 className={css.title}>{panelTitle}</h1> */}
      <NewJobInfoPanel />

      {form}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditProjectListingBudgetPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditProjectListingBudgetPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditProjectListingBudgetPanel;
