import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { required } from '../../util/validators';

import config from '../../config';
import pDebounce from 'p-debounce';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldSelect,
  FieldPhoneNumberInput,
  FieldSelectModern,
  FieldBirthdayInput,
  LocationAutocompleteInputField,
  ExternalLink,
} from '../../components';

import css from './SignupForm.css';

const KEY_CODE_ENTER = 13;
const industryOptions = config.custom.industryOptions;
const categoryOptions = config.custom.categoriesOptions;
const buyerLookingForOptions = config.custom.buyerLookingForOptions;
const buyerDurationOptions = config.custom.buyerDurationOptions;

const SignupFormComponent = props => {
  const emailNotLegitMessage = props.intl.formatMessage({
    id: 'SignupForm.emailNotLegit',
  });

  const emailLegit = validators.validateEmailDomain(emailNotLegitMessage);
  const emailLegitDebounce = pDebounce(emailLegit, 1000);

  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          values,
          invalid,
          intl,
          onOpenTermsOfService,
          isSignupProvider,
        } = fieldRenderProps;

        // email
        const emailPlaceholder = intl.formatMessage({
          id: 'SignupForm.emailPlaceholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'SignupForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'SignupForm.emailNotValidWarning',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        // password
        const passwordPlaceholder = intl.formatMessage({
          id: 'SignupForm.passwordPlaceholder',
        });
        const passwordRequiredMessage = intl.formatMessage({
          id: 'SignupForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength
        );

        // firstName
        const firstNamePlaceholder = intl.formatMessage({
          id: 'SignupForm.firstNamePlaceholder',
        });
        const firstNameRequiredMessage = intl.formatMessage({
          id: 'SignupForm.firstNameRequired',
        });
        const firstNameRequired = validators.required(firstNameRequiredMessage);

        // lastName
        const lastNamePlaceholder = intl.formatMessage({
          id: 'SignupForm.lastNamePlaceholder',
        });
        const lastNameRequiredMessage = intl.formatMessage({
          id: 'SignupForm.lastNameRequired',
        });
        const lastNameRequired = validators.required(lastNameRequiredMessage);

        // Account Rolerm.accountRoleLabel
        const accountRolePlaceholder = intl.formatMessage({
          id: 'SignupForm.accountRolePlaceholder',
        });
        const accountRoleRequiredMessage = intl.formatMessage({
          id: 'SignupForm.accountRoleRequired',
        });
        const accountRoleRequired = validators.required(accountRoleRequiredMessage);

        const accountRoleFreelancerOption = intl.formatMessage({
          id: 'SignupForm.accountRoleFreelancerOption',
        });
        const accountRoleLightEntrepreneurOption = intl.formatMessage({
          id: 'SignupForm.accountRoleLightEntrepreneurOption',
        });
        const accountRoleCompanyAbroadOption = intl.formatMessage({
          id: 'SignupForm.accountRoleCompanyAbroadOption',
        });
        // VAT number

        const vatNumberOptionalPlaceholder = intl.formatMessage({
          id: 'SignupForm.vatNumberOptionalPlaceholder',
        });

        const vatNumberRequiredPlaceholder = intl.formatMessage({
          id: 'SignupForm.vatNumberRequiredPlaceholder',
        });

        const vatNumberRequiredMessage = intl.formatMessage({
          id: 'SignupForm.vatNumberRequired',
        });

        const birthdateLabel = intl.formatMessage({
          id: 'SignupProviderForms.birthdateLabel',
        });

        const birthLocationLabel = intl.formatMessage({
          id: 'SignupProviderForms.birthLocationLabel',
        });

        const locationLabel = intl.formatMessage({
          id: 'SignupProviderForms.locationNLanguageLocationLabel',
        });

        const locationPlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.locationNLanguageLocationPlaceholder',
        });

        const locationRequired = intl.formatMessage({
          id: 'SignupProviderForms.locationRequired',
        });

        const informationRequired = intl.formatMessage({
          id: 'SignupProviderForms.informationRequired',
        });

        const identity = v => v;

        const tinLabel = intl.formatMessage({
          id: 'SignupProviderForms.tinLabel',
        });

        const tinCountryLabel = intl.formatMessage({
          id: 'SignupProviderForms.tinCountryLabel',
        });

        const businessRegistrationNumberLabel = intl.formatMessage({
          id: 'SignupProviderForms.businessRegistrationNumberLabel',
        });

        const businessRegistrationCountryOfIssueLabel = intl.formatMessage({
          id: 'SignupProviderForms.businessRegistrationCountryOfIssueLabel',
        });

        const vatNumberLabel = intl.formatMessage({
          id: 'SignupForm.vatNumberLabel',
        });

        const companyLabel = intl.formatMessage({
          id: 'SignupProviderForms.companyLabel',
        });

        const currentAddressInformationLabel = intl.formatMessage({
          id: 'SignupProviderForms.currentAddressInformationLabel',
        });

        const addressLabel = intl.formatMessage({
          id: 'SignupProviderForms.addressLabel',
        });

        const cityLabel = intl.formatMessage({
          id: 'SignupProviderForms.cityLabel',
        });

        const postalCodeLabel = intl.formatMessage({
          id: 'SignupProviderForms.postalCodeLabel',
        });

        const phoneNumberRequiredMessage = intl.formatMessage({
          id: 'SignupForm.phoneNumberRequired',
        });

        const vatNumberRequired = validators.required(vatNumberRequiredMessage);

        const vatNumberOptional = validators.optional();

        const phoneNumberRequired = validators.phoneNumberRequired(phoneNumberRequiredMessage);

        const taxInformationInfo = (
          <FormattedMessage
            id="SignupForm.taxInformationInfo"
            values={{
              a: chunks => (
                <ExternalLink
                  key="linkToInstagram"
                  href="https://www.vero.fi/syventavat-vero-ohjeet/ohje-hakusivu/209369/raportoivan-alustaoperaattorin-tiedonantovelvollisuus-dac7/"
                >
                  {chunks}
                </ExternalLink>
              ),
            }}
          />
        );

        const taxInformationElement = () => {
          if (!isSignupProvider) {
            return (
              <FieldTextInput
                className={css.vat}
                type="text"
                id={formId ? `${formId}.vat_number` : 'vat_number'}
                name="vat_number"
                // label={vatNumberLabel}
                placeholder={vatNumberOptionalPlaceholder}
                validate={vatNumberOptional}
                key={values.accountRole ? 'customer' : 'freelancer'}
                autoFocus
              />
            );
          } else if (values.accountRole === 'freelancer') {
            return (
              <div>
                <h2 className={css.sectionTitle}>
                  <FormattedMessage id="SignupForm.taxInformationFreelancerTitle" />
                </h2>
                <p className={css.taxInformationDescription}>{taxInformationInfo}</p>
                <FieldTextInput
                  className={css.vat}
                  type="text"
                  id={formId ? `${formId}.companyName` : 'companyName'}
                  name="companyName"
                  // label={vatNumberLabel}
                  placeholder={companyLabel}
                  validate={required(informationRequired)}
                />
                <FieldTextInput
                  className={css.vat}
                  type="text"
                  id={formId ? `${formId}.vat_number` : 'vat_number'}
                  name="vat_number"
                  // label={vatNumberLabel}
                  placeholder={vatNumberRequiredPlaceholder}
                  validate={vatNumberRequired}
                  key={values.accountRole ? 'freelancer' : 'customer'}
                />
                <FieldTextInput
                  className={css.vat}
                  type="text"
                  id={formId ? `${formId}.address` : 'address'}
                  name="address"
                  placeholder={addressLabel}
                  validate={required(informationRequired)}
                />
                <FieldTextInput
                  className={css.vat}
                  type="text"
                  id={formId ? `${formId}.postalCode` : 'postalCode'}
                  name="postalCode"
                  placeholder={postalCodeLabel}
                  validate={required(informationRequired)}
                />
                <FieldTextInput
                  className={css.vat}
                  type="text"
                  id={formId ? `${formId}.city` : 'city'}
                  name="city"
                  placeholder={cityLabel}
                  validate={required(informationRequired)}
                />
              </div>
            );
          } else if (values.accountRole === 'light-entrepreneur') {
            return (
              <div>
                <h2 className={css.sectionTitle}>
                  <FormattedMessage id="SignupForm.taxInformationTitle" />
                </h2>
                <p className={css.taxInformationDescription}>{taxInformationInfo}</p>
                <LocationAutocompleteInputField
                  // className={css.vat}
                  labelClassName={css.label}
                  iconClassName={css.locationAutocompleteInputIcon}
                  predictionsClassName={css.predictionsRoot}
                  validClassName={css.validLocation}
                  name="birthLocation"
                  placeholder={birthLocationLabel}
                  label={birthLocationLabel}
                  useDefaultPredictions={false}
                  format={identity}
                  valueFromForm={values.location}
                  validate={required(informationRequired)}
                  predictionType="place"
                />
                <FieldBirthdayInput
                  className={css.birthday}
                  type="text"
                  id={formId ? `${formId}.dateOfBirth` : 'dateOfBirth'}
                  name="dateOfBirth"
                  label={birthdateLabel}
                  placeholder={birthdateLabel}
                  validate={required(informationRequired)}
                />
                <FieldTextInput
                  className={css.vat}
                  type="text"
                  id={formId ? `${formId}.address` : 'address'}
                  name="address"
                  label={currentAddressInformationLabel}
                  placeholder={addressLabel}
                  validate={required(informationRequired)}
                />
                <FieldTextInput
                  className={css.vat}
                  type="text"
                  id={formId ? `${formId}.postalCode` : 'postalCode'}
                  name="postalCode"
                  placeholder={postalCodeLabel}
                  validate={required(informationRequired)}
                />
                <FieldTextInput
                  className={css.vat}
                  type="text"
                  id={formId ? `${formId}.city` : 'city'}
                  name="city"
                  placeholder={cityLabel}
                  validate={required(informationRequired)}
                />
              </div>
            );
          } else if (values.accountRole === 'company-abroad') {
            return (
              <div>
                <h2 className={css.sectionTitle}>
                  <FormattedMessage id="SignupForm.taxInformationTitle" />
                </h2>
                <p className={css.taxInformationDescription}>{taxInformationInfo}</p>
                <FieldTextInput
                  className={css.vat}
                  type="text"
                  id={formId ? `${formId}.companyName` : 'companyName'}
                  name="companyName"
                  // label={vatNumberLabel}
                  placeholder={companyLabel}
                  validate={required(informationRequired)}
                />
                <FieldTextInput
                  className={css.vat}
                  type="text"
                  id={formId ? `${formId}.taxIdentificationNumber` : 'taxIdentificationNumber'}
                  name="taxIdentificationNumber"
                  placeholder={tinLabel}
                  validate={required(informationRequired)}
                />
                <LocationAutocompleteInputField
                  className={css.vat}
                  iconClassName={css.locationAutocompleteInputIcon}
                  predictionsClassName={css.predictionsRoot}
                  validClassName={css.validLocation}
                  name="tinCountry"
                  placeholder={tinCountryLabel}
                  useDefaultPredictions={false}
                  format={identity}
                  valueFromForm={values.location}
                  validate={required(informationRequired)}
                  // key={values.accountRole ? 'company-abroad' : 'freelancer'}
                  predictionType="country"
                />
                <FieldTextInput
                  className={css.vat}
                  type="text"
                  id={
                    formId ? `${formId}.businessRegistrationNumber` : 'businessRegistrationNumber'
                  }
                  name="businessRegistrationNumber"
                  placeholder={businessRegistrationNumberLabel}
                  validate={required(informationRequired)}
                />
                <LocationAutocompleteInputField
                  className={css.vat}
                  iconClassName={css.locationAutocompleteInputIcon}
                  predictionsClassName={css.predictionsRoot}
                  validClassName={css.validLocation}
                  name="businessRegistrationCountryOfIssue"
                  placeholder={businessRegistrationCountryOfIssueLabel}
                  useDefaultPredictions={false}
                  format={identity}
                  valueFromForm={values.location}
                  validate={required(informationRequired)}
                  // key={values.accountRole ? 'company-abroad' : 'freelancer'}
                  predictionType="country"
                />
                <FieldTextInput
                  className={css.vat}
                  type="text"
                  id={formId ? `${formId}.vat_number` : 'vat_number'}
                  name="vat_number"
                  placeholder={vatNumberOptionalPlaceholder}
                  validate={vatNumberOptional}
                  key={values.accountRole ? 'company-abroad' : 'freelancer'}
                />
                <FieldTextInput
                  className={css.vat}
                  type="text"
                  id={formId ? `${formId}.address` : 'address'}
                  name="address"
                  placeholder={addressLabel}
                  validate={required(informationRequired)}
                />
                <FieldTextInput
                  className={css.vat}
                  type="text"
                  id={formId ? `${formId}.postalCode` : 'postalCode'}
                  name="postalCode"
                  placeholder={postalCodeLabel}
                  validate={required(informationRequired)}
                />
                <FieldTextInput
                  className={css.vat}
                  type="text"
                  id={formId ? `${formId}.city` : 'city'}
                  name="city"
                  placeholder={cityLabel}
                  validate={required(informationRequired)}
                />
              </div>
            );
          } else {
            return null;
          }
        };

        const phoneNumberElement = () => {
          return (
            <div className={css.phoneNumber}>
              <FieldPhoneNumberInput
                // className={css.phoneNumber}
                type="text"
                id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                name="phoneNumber"
                // autoComplete="phoneNumber"
                // label={phoneNumberLabel}
                // placeholder={phoneNumberPlaceholder}
                validate={phoneNumberRequired}
              />
              <div className={css.underFieldLabel}>
                <FormattedMessage id="SignupForm.privatePhoneNumberNote" />
              </div>
            </div>
          );
        };

        // industry

        const industryPlaceholder = intl.formatMessage({
          id: 'SignupForm.industryPlaceholder',
        });

        const industryOptional = validators.optional();

        const industryElement = () => {
          if (!isSignupProvider) {
            return (
              <FieldSelectModern
                className={css.industry}
                id={formId ? `${formId}.industry` : 'industry'}
                name="industry"
                // label={industryLabel}
                validate={industryOptional}
                options={industryOptions}
                placeholder={industryPlaceholder}
              />
            );
          } else {
            return null;
          }
        };

        const lookingForPlaceholder = intl.formatMessage({
          id: 'SignupForm.lookingForPlaceholder',
        });

        const lookingForRequiredMessage = intl.formatMessage({
          id: 'SignupForm.lookingForRequired',
        });

        const lookingForRequired = validators.required(lookingForRequiredMessage);

        const durationPlaceholder = intl.formatMessage({
          id: 'SignupForm.durationPlaceholder',
        });

        const durationRequiredMessage = intl.formatMessage({
          id: 'SignupForm.durationRequired',
        });

        const durationRequired = validators.required(durationRequiredMessage);

        const lookingForElement = !isSignupProvider ? (
          <FieldSelectModern
            className={css.lookingFor}
            id={formId ? `${formId}.lookingFor` : 'lookingFor'}
            name="lookingFor"
            validate={lookingForRequired}
            options={buyerLookingForOptions}
            placeholder={lookingForPlaceholder}
          />
        ) : null;

        const durationElement = !isSignupProvider ? (
          <FieldSelectModern
            className={css.lookingFor}
            id={formId ? `${formId}.projectDuration` : 'projectDuration'}
            name="projectDuration"
            validate={durationRequired}
            options={buyerDurationOptions}
            placeholder={durationPlaceholder}
          />
        ) : null;

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;

        const handleTermsKeyUp = e => {
          // Allow click action with keyboard like with normal links
          if (e.keyCode === KEY_CODE_ENTER) {
            onOpenTermsOfService();
          }
        };
        const termsLink = (
          <span
            className={css.termsLink}
            onClick={onOpenTermsOfService}
            role="button"
            tabIndex="0"
            onKeyUp={handleTermsKeyUp}
          >
            <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
          </span>
        );

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div>
              <div style={{ display: 'flex' }}>
                <div style={{ flexGrow: 3 }}>
                  <FieldTextInput
                    type="email"
                    id={formId ? `${formId}.email` : 'email'}
                    name="email"
                    autoComplete="email"
                    // label={emailLabel}
                    placeholder={emailPlaceholder}
                    validate={validators.composeValidators(
                      emailRequired,
                      emailValid,
                      emailLegitDebounce
                    )}
                  />
                  <div className={css.underFieldLabel}>
                    <FormattedMessage id="SignupForm.validEmailInstructions" />
                  </div>
                </div>
              </div>
              {!isSignupProvider ? (
                <div className={css.name}>
                  <FieldTextInput
                    className={css.firstNameRoot}
                    type="text"
                    id={formId ? `${formId}.fname` : 'fname'}
                    name="fname"
                    autoComplete="given-name"
                    placeholder={firstNamePlaceholder}
                    validate={firstNameRequired}
                  />
                  <FieldTextInput
                    className={css.lastNameRoot}
                    type="text"
                    id={formId ? `${formId}.lname` : 'lname'}
                    name="lname"
                    autoComplete="family-name"
                    placeholder={lastNamePlaceholder}
                    validate={lastNameRequired}
                  />
                </div>
              ) : null}

              <FieldTextInput
                className={css.password}
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                // label={passwordLabel}
                placeholder={passwordPlaceholder}
                validate={passwordValidators}
              />
              {phoneNumberElement()}
              {isSignupProvider ? (
                <FieldSelect
                  className={css.role}
                  id={formId ? `${formId}.accountRole` : 'accountRole'}
                  name="accountRole"
                  // label={accountRoleLabel}
                  validate={accountRoleRequired}
                >
                  <option value="">{accountRolePlaceholder}</option>
                  <option value="freelancer">{accountRoleFreelancerOption}</option>
                  <option value="light-entrepreneur">{accountRoleLightEntrepreneurOption}</option>
                  <option value="company-abroad">{accountRoleCompanyAbroadOption}</option>
                </FieldSelect>
              ) : null}
              {taxInformationElement()}
              {industryElement()}
              {lookingForElement}
              {durationElement}
            </div>

            <div className={css.bottomWrapper}>
              <p className={css.bottomWrapperText}>
                <span className={css.termsText} id="terms">
                  <FormattedMessage
                    id="SignupForm.termsAndConditionsAcceptText"
                    values={{ termsLink }}
                  />
                </span>
              </p>
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="SignupForm.signUp" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
