import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ProfileSettingsContainer as Container } from '../../components';
import css from './Container.css';

const WorkExperienceContainer = ({ profileUser, button }) => {
  const workExperience = profileUser?.attributes?.profile?.publicData?.workExperience;

  const container = (
    <Container heading={<FormattedMessage id="ProfilePage.workExperience" />} button={button}>
      {workExperience &&
        workExperience[0]?.startYear !== null &&
        workExperience.map(e => (
          <div key={e.customerName} className={css.container}>
            <div className={css.heading}>
              {e.customerName}
              <div className={css.text}>{e.task}</div>
            </div>
            <div className={css.text}>{e.description}</div>
            <div className={css.heading}>
              <FormattedMessage id="ProfilePage.workExperienceDuration" />
            </div>
            <div>
              {e.startMonth}/{e.startYear}
              {e.jobContinues ? (
                <FormattedMessage id="ProfilePage.workExperienceDurationOngoing" />
              ) : (
                ` - ${e.endMonth}/${e.endYear}`
              )}
            </div>
          </div>
        ))}
    </Container>
  );

  if (!button) {
    if (
      !workExperience?.length ||
      (workExperience?.length === 0 && Object.keys(workExperience[0]).length === 0)
    )
      return null;
  }
  return container;
};

export default WorkExperienceContainer;
