import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import { injectIntl, FormattedMessage } from '../../util/reactIntl';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  SignupProviderInfoStep,
  SignupProviderBioForm,
  SignupProviderEducationForm,
  SignupProviderHourlyFeeForm,
  SignupProviderLocationNLanguageForm,
  SignupProviderSkillsForm,
  SignupProviderWhoAmIFrom,
  SignupProviderWorkedWithForm,
  SignupProviderWorkExperienceForm,
  SignupProviderLinksForm,
  SignupProviderCOC,
} from '../../forms';

import {
  LayoutSingleColumn,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  ProgressBar,
  Modal,
  TopbarBlank,
} from '../../components';
import { AuthenticationPage } from '../../containers';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import config from '../../config';

import css from './SignupProviderPage.css';

const renderSignUpStep = (step, next, prev, formData, nextFromInfo) => {
  switch (step) {
    case 0:
      return <SignupProviderInfoStep onSubmit={nextFromInfo} initialValues={formData} />;
    case 1:
      return <SignupProviderWhoAmIFrom onSubmit={next} initialValues={formData} />;
    case 2:
      return (
        <SignupProviderSkillsForm
          onSubmit={values => {
            const { experience } = values;
            const newExperience = experience.map(item =>
              item.isMainSkill ? item : { ...item, isMainSkill: false }
            );
            next({ experience: newExperience });
          }}
          onPrev={prev}
          initialValues={formData}
        />
      );
    case 3:
      return <SignupProviderHourlyFeeForm onSubmit={next} onPrev={prev} initialValues={formData} />;
    case 4:
      return (
        <SignupProviderLocationNLanguageForm
          onSubmit={next}
          onPrev={prev}
          initialValues={formData}
        />
      );
    case 5:
      return <SignupProviderLinksForm onSubmit={next} onPrev={prev} initialValues={formData} />;
    case 6:
      return <SignupProviderEducationForm onSubmit={next} onPrev={prev} initialValues={formData} />;
    case 7:
      return (
        <SignupProviderWorkExperienceForm
          onSubmit={values => {
            const { workExperience, dontAddWorkExperience } = values;
            const newWorkExperience = workExperience.map(item =>
              item.jobContinues ? { ...item, endMonth: undefined, endYear: undefined } : item
            );

            next({ workExperience: newWorkExperience, dontAddWorkExperience });
          }}
          onPrev={prev}
          initialValues={formData}
        />
      );
    case 8:
      return (
        <SignupProviderWorkedWithForm
          onSubmit={values => {
            const { workedWith, dontAddWorkedWith } = values;
            const newWorkedWith = workedWith.map(item =>
              item.jobContinues ? { ...item, endMonth: undefined, endYear: undefined } : item
            );
            next({ workedWith: newWorkedWith, dontAddWorkedWith });
          }}
          onPrev={prev}
          initialValues={formData}
        />
      );
    case 9:
      return <SignupProviderBioForm onSubmit={next} onPrev={prev} initialValues={formData} />;
    case 10:
      return (
        <SignupProviderCOC
          onSubmit={values => {
            const { cocAccepted, fnameProvider, lnameProvider } = values;
            next({ cocAccepted, fnameProvider, lnameProvider });
          }}
          onPrev={prev}
          initialValues={formData}
        />
      );
    default:
      return null;
  }
};

const initialFormData = {
  // Array with one empty object for FieldArrays initially to have one set of fields visible on first render.
  experience: [{}],
  languages: [{}],
  education: [{}],
  workExperience: [{}],
  workedWith: [{}],
};

const SignupProviderPageComponent = props => {
  const { onManageDisableScrolling, scrollingDisabled, intl } = props;
  const [isAuthOpen, setIsAuthOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState(initialFormData);

  const nextFromInfo = () => {
    setStep(prevStep => prevStep + 1);
  };

  const next = data => {
    const newFormData = {
      ...formData,
      ...data,
    };

    setFormData(newFormData);

    return step === 10 ? setUpLastStep(newFormData) : setStep(prevStep => prevStep + 1);
  };

  const prev = e => {
    e.preventDefault();
    setStep(prevStep => prevStep - 1);
  };

  const setUpLastStep = data => {
    if (typeof window !== 'undefined' && !!window.sessionStorage) {
      sessionStorage.setItem('providerFormData', JSON.stringify(data));
    }
    setIsAuthOpen(true);
  };

  const completedValue = ((step / 9) * 100).toFixed(2);

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage(
    { id: 'AuthenticationPage.schemaTitleSignup' },
    { siteTitle }
  );

  const nextUpTranslations = [
    { id: 0, translation: 'SignupProviderForms.nextUpStart' },
    { id: 1, translation: 'SignupProviderForms.nextUpSkills' },
    { id: 2, translation: 'SignupProviderForms.nextUpHourlyFee' },
    { id: 3, translation: 'SignupProviderForms.nextUpLocationNLanguage' },
    { id: 4, translation: 'SignupProviderForms.nextUpLinks' },
    { id: 5, translation: 'SignupProviderForms.nextUpEducation' },
    { id: 6, translation: 'SignupProviderForms.nextUpExperience' },
    { id: 7, translation: 'SignupProviderForms.nextUpWorkedWith' },
    { id: 8, translation: 'SignupProviderForms.nextUpBio' },
    { id: 9, translation: 'SignupProviderForms.nextUpCOC' },
    { id: 10, translation: 'SignupProviderForms.nextUpFinish' },
  ];

  const getNextUpTranslation = () => {
    const { translation } = nextUpTranslations.find(t => t.id === step);
    return translation;
  };

  const showUnsavedPrompt = !isEqual(formData, initialFormData);

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarBlank />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.main}>
            {renderSignUpStep(step, next, prev, formData, nextFromInfo)}
            <ProgressBar value={completedValue} stepsDone={step} steps={9} hideStepCount />
            <div className={css.nextUpContainer}>
              <div className={css.nextUpLine}>
                <span className={css.nextUpText}>
                  <FormattedMessage id={getNextUpTranslation()} />
                </span>
              </div>
            </div>
            <Modal
              id="SignupProviderAuthModal"
              containerClassName={css.modal}
              isOpen={isAuthOpen}
              onClose={() => setIsAuthOpen(false)}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <AuthenticationPage tab="signup" showUnsavedPrompt={showUnsavedPrompt} />
            </Modal>
          </div>
        </LayoutWrapperMain>
      </LayoutSingleColumn>
    </Page>
  );
};

SignupProviderPageComponent.defaultProps = {};

SignupProviderPageComponent.propTypes = {};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SignupProviderPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SignupProviderPageComponent);

export default SignupProviderPage;
