import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { propTypes } from '../../util/types';
import { required, textEditorValidation, composeValidators } from '../../util/validators';
import { InfoIcon, Form, Button, FieldTextEditor, UnsavedInfoPrompt } from '../../components';

import css from './EditProjectListingDescriptionForm.css';

const EditProjectListingDescriptionFormComponent = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          dirty,
        } = formRenderProps;

        const descriptionRequiredMessage = intl.formatMessage({
          id: 'EditProjectListingDescriptionForm.descriptionRequiredMessage',
        });

        const descriptionMessage = intl.formatMessage({
          id: 'EditProjectListingDescriptionForm.description',
        });

        const descriptionInfoHeader = intl.formatMessage({
          id: 'EditProjectListingDescriptionForm.descriptionInfoHeader',
        });
        const descriptionInfoFirst = intl.formatMessage({
          id: 'EditProjectListingDescriptionForm.descriptionInfoFirst',
        });
        const descriptionInfoSecond = intl.formatMessage({
          id: 'EditProjectListingDescriptionForm.descriptionInfoSecond',
        });
        const descriptionInfoThird = intl.formatMessage({
          id: 'EditProjectListingDescriptionForm.descriptionInfoThird',
        });
        const descriptionPlaceholderMessage = intl.formatMessage({
          id: 'EditProjectListingDescriptionForm.descriptionPlaceholder',
        });

        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditProjectListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditProjectListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditProjectListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;

        const externalLinksMessage = intl.formatMessage({
          id: 'RichTextEditor.externalLinksError',
        });

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessageCreateListingDraft}
            {errorMessageUpdateListing}
            {errorMessageShowListing}
            {typeof window === 'object' ? <ReactTooltip /> : null}

            <div className={css.titleBlock}>
              <label htmlFor="description" className={css.descriptionMessage}>
                {descriptionMessage}
              </label>
              <InfoIcon
                className={css.infoIcon}
                data-tip={intl.formatMessage({
                  id: 'EditProjectListingDescriptionForm.descriptionTooltip',
                })}
                data-border={true}
                data-text-color={'black'}
                data-background-color={'white'}
                data-border-color={'darkgrey'}
                data-class={css.tooltipText}
                data-place={'bottom'}
              />
            </div>
            <div>
              <FieldTextEditor
                id="descriptionText"
                name="descriptionText"
                // label={descriptionMessage}
                className={css.description}
                type="textarea"
                placeholder={descriptionPlaceholderMessage}
                validate={composeValidators(
                  required(descriptionRequiredMessage),
                  textEditorValidation(externalLinksMessage)
                )}
              />
            </div>
            <div className={css.infoBlock}>
              <div className={css.infoList}>
                <ul className={css.descriptionInfoHeader}>
                  {descriptionInfoHeader}
                  <li className={css.infoListItem}>{descriptionInfoFirst}</li>
                  <li className={css.infoListItem}>{descriptionInfoSecond}</li>
                  <li className={css.infoListItem}>{descriptionInfoThird}</li>
                </ul>
              </div>
            </div>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
            <UnsavedInfoPrompt when={dirty} />
          </Form>
        );
      }}
    />
  );
};

EditProjectListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditProjectListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditProjectListingDescriptionFormComponent);
