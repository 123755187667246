import React from 'react';
import { FormattedMessage } from 'react-intl';
import config from '../../config';
import { ProfileSettingsContainer as Container } from '../../components';
import css from './Container.css';

const TopSkillsContainer = ({ profileUser, button }) => {
  const experience = profileUser?.attributes?.profile?.publicData?.experience;
  const skillLevelOptions = config.custom.skillLevelOptions;

  const container = (
    <Container heading={<FormattedMessage id="ProfilePage.skills" />} button={button}>
      {experience &&
        experience.map(e => (
          <div key={e.category} className={css.skillContainer}>
            <div className={css.heading}>
              {config.custom.skillOptions.find(c => c.key === e.category)?.label}
            </div>
            <div className={css.years}>
              {e.category && e.level && (
                <FormattedMessage id={skillLevelOptions.find(s => s.key === e.level)?.label} />
              )}
            </div>
          </div>
        ))}
    </Container>
  );

  // Chech whether there is a single object with null values. Used object values so program
  // won't break on several re-renders.
  if (!button) {
    if (
      !experience?.length ||
      (Object.values(experience[0])[0] === null && Object.values(experience[0])[1] === null)
    )
      return null;
  }
  return container;
};

export default TopSkillsContainer;
