/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { IoIosArrowDown, IoIosArrowUp, IoMdSettings, IoMdHelpCircle } from 'react-icons/io';
import { IoLogOut } from 'react-icons/io5';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import config from '../../config';
import { InlineTextButton, NamedLink, ExternalLink, NotificationBadge } from '../../components';

import css from './TopbarMobileMenu.css';

const filters = config.custom.filters;

const Dropdown = ({ parent, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => setIsOpen(!isOpen);

  return (
    <div className={css.dropdownContainer} onClick={handleClick}>
      <div className={classNames(css.dropdownParent, css.darkLink)}>
        {parent}
        {isOpen ? <IoIosArrowUp onClick={handleClick} /> : <IoIosArrowDown onClick={handleClick} />}
      </div>
      {isOpen && children}
    </div>
  );
};

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    applicationNotificationCount,
    onLogout,
  } = props;

  const engUrl = process.env.REACT_APP_ENGLISH_ROOT_URL;
  const fiUrl = process.env.REACT_APP_FINNISH_ROOT_URL;

  const currentUrl = window.location.href;

  const categories = filters.find(filter => filter.id === 'category').config.options;

  const handleLanguageChange = e => {
    if (e.target.value === 'en') {
      window.location.assign(process.env.REACT_APP_ENGLISH_ROOT_URL);
    } else if (e.target.value === 'fi') {
      window.location.assign(process.env.REACT_APP_FINNISH_ROOT_URL);
    }
  };

  const preventDefault = e => e.preventDefault();

  const languageSelect = currentUrl.startsWith(engUrl) ? (
    <select
      id="language"
      name="language"
      onChange={handleLanguageChange}
      className={css.languageSelect}
    >
      <option defaultValue value="en">
        EN
      </option>
      <option value="fi">FI</option>
    </select>
  ) : currentUrl.startsWith(fiUrl) ? (
    <select
      id="language"
      name="language"
      onChange={handleLanguageChange}
      className={css.languageSelect}
    >
      <option defaultValue value="fi">
        FI
      </option>
      <option value="en">EN</option>
    </select>
  ) : null;

  const categoryDropDownParent = <FormattedMessage id="TopbarSearchForm.searchForServices" />;

  const whyFreedomlyDropdown = (
    <Dropdown parent={<FormattedMessage id="TopbarMobileMenu.recourses" />}>
      <NamedLink
        className={classNames(css.darkLink, css.dropdownChild)}
        name="DigitalAgencySolutionPage"
      >
        <FormattedMessage id="TopbarMobileMenu.digitalAgencies" />
      </NamedLink>
      <NamedLink
        className={classNames(css.darkLink, css.dropdownChild)}
        name="MarketingTeamSolutionPage"
      >
        <FormattedMessage id="TopbarMobileMenu.marketingTeams" />
      </NamedLink>
      <NamedLink
        className={classNames(css.darkLink, css.dropdownChild)}
        name="SmallBusinessSolutionPage"
      >
        <FormattedMessage id="TopbarMobileMenu.smallBusinesses" />
      </NamedLink>
    </Dropdown>
  );

  const categoryDropdown = (
    <Dropdown parent={categoryDropDownParent}>
      {categories.map(category => {
        return (
          category.parent === '' && (
            <NamedLink
              className={classNames(css.darkLink, css.categoryLink)}
              name={category.name}
              key={category}
            >
              {category.label}
            </NamedLink>
          )
        );
      })}
    </Dropdown>
  );

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.blueLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.blueLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const forCustomer = (
      <div>
        <NamedLink name="StartBuyingInfoPage" className={css.darkLink}>
          <FormattedMessage id="TopBarMobileMenu.forCustomerLink" />
        </NamedLink>
      </div>
    );

    const forFreelancer = (
      <NamedLink name="StartSellingInfoPage" className={css.darkLink}>
        <FormattedMessage id="TopBarMobileMenu.forFreelancerLink" />
      </NamedLink>
    );

    const freedomlyPro = (
      <NamedLink className={css.darkLink} name="ProLandingPage">
        <FormattedMessage id="TopbarDesktop.proLandingPage" />
      </NamedLink>
    );

    const reviews = (
      <NamedLink className={css.darkLink} name="ReviewsPage">
        <FormattedMessage id="SectionHowItWorks.linkToReviewsPage" />
      </NamedLink>
    );

    const successStories = (
      <NamedLink className={css.darkLink} name="SuccessStoriesPage">
        <FormattedMessage id="TopbarDesktop.successStoriesPage" />
      </NamedLink>
    );

    const aboutUs = (
      <NamedLink className={css.darkLink} name="AboutUsPage">
        <FormattedMessage id="Footer.toAboutPage" />
      </NamedLink>
    );

    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.linkSection}>{languageSelect}</div>
          <hr className={css.navigationDivider} />
          <div className={css.linkSection}>
            {login}
            {signup}
          </div>
          <hr className={css.navigationDivider} />
          <div className={css.linkSection}>
            {whyFreedomlyDropdown}
            {/* {categoryDropdown} */}
            {freedomlyPro}
            {reviews}
            {successStories}
            {aboutUs}
          </div>
          <hr className={css.navigationDivider} />
          <div className={css.linkSection}>
            {forFreelancer}
            {forCustomer}
            <ExternalLink href="https://help.freedomly.io/fi/" className={css.darkLink}>
              <FormattedMessage id="TopbarMobileMenu.support" />
            </ExternalLink>
          </div>
          <div className={css.footer}>
            <NamedLink className={css.createNewListingLink} name="SignupPage">
              <FormattedMessage id="TopbarMobileMenu.signupLink" />
            </NamedLink>
          </div>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const applicationNotificationCountBadge =
    applicationNotificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={applicationNotificationCount} />
    ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const accountRole =
    currentUser &&
    currentUser.attributes.profile.publicData &&
    currentUser.attributes.profile.publicData.account_role;

  const newListingLink =
    currentUser &&
    typeof accountRole === 'string' &&
    ['freelancer', 'light-entrepreneur'].includes(accountRole) ? (
      <NamedLink className={css.blueLink} name="NewListingPage">
        <FormattedMessage id="TopbarMobileMenu.newListingLink" />
      </NamedLink>
    ) : null;

  const newProjectListingLink =
    currentUser && typeof accountRole === 'string' && accountRole === 'customer' ? (
      <NamedLink className={css.blueLink} name="NewProjectListingPage">
        <FormattedMessage id="TopbarMobileMenu.newProjectListingLink" />
      </NamedLink>
    ) : null;

  const manageListingsLink =
    currentUser &&
    typeof accountRole === 'string' &&
    ['freelancer', 'light-entrepreneur'].includes(accountRole) ? (
      <NamedLink
        className={classNames(css.blueLink, currentPageClass('ManageListingsPage'))}
        name="ManageListingsPage"
      >
        <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
      </NamedLink>
    ) : (
      <NamedLink
        className={classNames(css.blueLink, currentPageClass('ManageProjectListingsPage'))}
        name="ManageProjectListingsPage"
      >
        <FormattedMessage id="TopbarMobileMenu.yourProjectsLink" />
      </NamedLink>
    );

  const infoPageLink =
    currentUser &&
    typeof accountRole === 'string' &&
    ['freelancer', 'light-entrepreneur'].includes(accountRole) ? (
      <NamedLink
        className={classNames(css.darkLink, currentPageClass('ManageListingsPage'))}
        name="StartSellingInfoPage"
      >
        <FormattedMessage id="TopBarMobileMenu.forFreelancerLink" />
      </NamedLink>
    ) : (
      <NamedLink
        className={classNames(css.darkLink, currentPageClass('ManageProjectListingsPage'))}
        name="StartBuyingInfoPage"
      >
        <FormattedMessage id="TopBarMobileMenu.forCustomerLink" />
      </NamedLink>
    );

  const accountType =
    currentUser &&
    currentUser.attributes.profile.publicData &&
    currentUser.attributes.profile.publicData.account_role;

  const proLandingPage = !isAuthenticated ? (
    <NamedLink className={css.proLandingPageLink} name="ProLandingPage">
      <FormattedMessage id="TopbarDesktop.proLandingPage" />
    </NamedLink>
  ) : isAuthenticated &&
    currentUser &&
    typeof accountType === 'string' &&
    ['customer'].includes(accountType) ? (
    <NamedLink className={css.darkLink} name="ProLandingPage">
      <FormattedMessage id="TopbarDesktop.proLandingPage" />
    </NamedLink>
  ) : null;

  return (
    <div className={css.root}>
      <div className={css.content}>
        <div className={css.linkSection}>{languageSelect}</div>
        <hr className={css.navigationDivider} />
        <div className={css.linkSection}>
          {currentUser ? (
            <NamedLink name="DashboardPage" className={css.blueLink}>
              <FormattedMessage id="TopbarMobileMenu.dashboardLink"></FormattedMessage>
            </NamedLink>
          ) : null}
          <NamedLink
            className={classNames(css.blueLink, currentPageClass('InboxPage'))}
            name="InboxPage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <FormattedMessage id="TopbarMobileMenu.inboxLink" />
            {notificationCountBadge}
          </NamedLink>
          <NamedLink
            className={classNames(css.blueLink, currentPageClass('ApplicationInboxPage'))}
            name="ApplicationInboxPage"
          >
            <FormattedMessage id="TopbarMobileMenu.applicationInboxLink" />
            {applicationNotificationCountBadge}
          </NamedLink>
          {manageListingsLink}
          {newListingLink}
          {newProjectListingLink}
        </div>
        <hr className={css.navigationDivider} />
        <div className={css.linkSection}>
          {/* {categoryDropdown} */}
          {proLandingPage}
          {infoPageLink}
        </div>
        <hr className={css.navigationDivider} />
        <div className={css.linkSection}>
          <Dropdown
            parent={
              <div onClick={preventDefault}>
                <IoMdSettings className={css.icon} />
                <FormattedMessage id="TopbarMobileMenu.settings" />
              </div>
            }
          >
            <NamedLink
              className={classNames(
                css.darkLink,
                currentPageClass('ProfileSettingsPage'),
                css.dropdownChild
              )}
              name="ProfileSettingsPage"
            >
              <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
            </NamedLink>
            <NamedLink
              className={classNames(
                css.darkLink,
                currentPageClass('AccountSettingsPage'),
                css.dropdownChild
              )}
              name="AccountSettingsPage"
            >
              <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
            </NamedLink>
          </Dropdown>
          <ExternalLink href="https://help.freedomly.io/fi/" className={css.darkLink}>
            <IoMdHelpCircle className={css.icon} />
            <FormattedMessage id="TopbarMobileMenu.support" />
          </ExternalLink>
          <InlineTextButton rootClassName={[css.logoutButton, css.darkLink]} onClick={onLogout}>
            <IoLogOut className={css.icon} />
            <FormattedMessage id="TopbarMobileMenu.logoutLink" />
          </InlineTextButton>
        </div>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  notificationCount: 0,
  applicationNotificationCount: 0,
  currentPage: null,
};

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  applicationNotificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
