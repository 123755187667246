import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import { FaCalendarCheck, FaBriefcase } from 'react-icons/fa';
import { BsFillPersonFill } from 'react-icons/bs';

import css from './ProjectListingPage.css';

const SectionIconsMaybe = props => {
  const { publicData, wrapperClassName } = props;

  const priceEstimate = publicData?.priceEstimate;
  const projectDuration =
    publicData?.projectDuration === 'project' ? (
      <FormattedMessage id="ProjectListingPage.projectDurationProject" />
    ) : publicData?.projectDuration === 'continuous' ? (
      <FormattedMessage id="ProjectListingPage.projectDurationContinuous" />
    ) : null;
  const projectTier = publicData?.projectTier;

  const projectTierText =
    projectTier === 'all' ? (
      <FormattedMessage id="ProjectListingPage.projectTierAll" />
    ) : projectTier === 'entry' ? (
      <FormattedMessage id="ProjectListingPage.projectTierEntry" />
    ) : projectTier === 'junior' ? (
      <FormattedMessage id="ProjectListingPage.projectJunior" />
    ) : projectTier === 'midlevel' ? (
      <FormattedMessage id="ProjectListingPage.projectMidlevel" />
    ) : projectTier === 'senior' ? (
      <FormattedMessage id="ProjectListingPage.projectTierSenior" />
    ) : null;

  const priceEstimateHeader =
    publicData?.projectDuration === 'project' ? (
      <FormattedMessage id="ProjectListingPage.priceEstimateHeaderProject" />
    ) : (
      <FormattedMessage id="ProjectListingPage.priceEstimateHeaderContinuous" />
    );

  const projectDurationHeader = <FormattedMessage id="ProjectListingPage.projectDurationHeader" />;
  const projectTierHeader = <FormattedMessage id="ProjectListingPage.projectTierHeader" />;

  return publicData ? (
    <div className={wrapperClassName ? wrapperClassName : css.sectionIconWrapper}>
      <div className={css.sectionIcon}>
        <FaBriefcase className={css.icon} />
        <div className={css.sectionIconHeader}>{priceEstimateHeader}</div>
        <div className={css.sectionIconText}>{priceEstimate}</div>
      </div>
      <div className={css.sectionIcon}>
        <FaCalendarCheck className={css.icon} />
        <div className={css.sectionIconHeader}>{projectDurationHeader}</div>
        <div className={css.sectionIconText}>{projectDuration}</div>
      </div>
      <div className={css.sectionIcon}>
        <BsFillPersonFill className={css.icon} />
        <div className={css.sectionIconHeader}>{projectTierHeader}</div>
        <div className={css.sectionIconText}>{projectTierText}</div>
      </div>
    </div>
  ) : null;
};

export default SectionIconsMaybe;
