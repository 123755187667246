import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { EditProjectListingDescriptionForm } from '../../forms';
import config from '../../config';
import { NewJobInfoPanel } from '../../components';
import css from './EditProjectListingDescriptionPanel.css';

const buildCategoryHash = categories => {
  const categoriesHash = {};

  categories.forEach(category => {
    categoriesHash[category.key] = category;
  });

  return categoriesHash;
};

const EditProjectListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData } = currentListing.attributes;

  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
  const categoryHash = buildCategoryHash(categoryOptions);
  return (
    <div className={classes}>
      {/* <h1 className={css.title}>{panelTitle}</h1> */}
      <NewJobInfoPanel />

      <EditProjectListingDescriptionForm
        className={css.form}
        initialValues={{
          title,
          category: publicData.category,
          descriptionText: publicData?.descriptionText ?? description,
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { title, category = 'seo', descriptionText } = values;

          const categoryObject = categoryHash[category];
          const categoryNodes = [category];
          if (typeof categoryObject.parent === 'string' && categoryObject.parent.length > 0)
            categoryNodes.push(categoryObject.parent);
          const updateValues = {
            title: title.trim(),
            publicData: {
              category,
              categoryNodes,
              descriptionText,
            },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        categories={categoryOptions}
      />
    </div>
  );
};

EditProjectListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditProjectListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditProjectListingDescriptionPanel;
